import { BreakpointsOptions } from '@mui/material/styles';

/**
 * Default grid
 * https://material-ui.com/customization/default-theme/
 */
export const grid: BreakpointsOptions = {
	values: {
		xs: 0,
		sm: 600,
		md: 900,
		lg: 1180,
		xl: 1440,
	},
};

export default grid;
