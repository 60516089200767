import { IncomingMessage } from 'http';

const getServerCookie = (
	cname: string,
	req: IncomingMessage & Record<string, any>,
): string | undefined => {
	const cookies = req.cookies;
	return cookies[cname];
};

const getCookie = (cname: string) => {
	const name = `${cname}=`;
	const ca = decodeURIComponent(document.cookie).split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};

export { getCookie, getServerCookie };
