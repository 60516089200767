import {
	AddressCreationAttributes,
	IUser,
	RGType,
	State,
	UserType,
} from '@interfaces/index';
import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';

export enum UserSelectionStatus {
	UPLOADED = 'UPLOADED',
	PROCESSING = 'PROCESSING',
	PROCESSED = 'PROCESSED',
	FAILED = 'FAILED',
}
export interface IUserSelection extends ISequelizeModel {
	id: string;
	label: string;
	desc?: string;
	userFilePath: string;
	columnMapping?: string;
	importMissingUsers: boolean;
	updateIfExists?: boolean;
	processOnly: boolean;
	status: UserSelectionStatus;
	result?: string;
	resultFile?: string;
}

export type UserSelectionCreationAttributes = Omit<
	IUserSelection,
	SequelizeModelKeys
>;

export interface IUserSelectionInput {
	label: string;
	desc?: string;
	userFilePath: string;
	columnMapping: string;
	importMissingUsers?: boolean;
	updateIfExists?: boolean;
	processOnly?: boolean;
}

export interface IUserSelectionUser extends ISequelizeModel {
	id: string;
	userId: string;
	user: IUser;
	email?: string;
	userSelectionId: string;
	userSelection: IUserSelection;
	cta?: string | null;
}

export type UserSelectionUserCreationAttributes = Omit<
	IUserSelectionUser,
	SequelizeModelKeys | 'user' | 'userSelection'
>;

export type ImportAddress = Omit<
	AddressCreationAttributes,
	| 'user'
	| 'userId'
	| 'refId'
	| 'googlePlaceId'
	| 'longitude'
	| 'latitude'
	| 'state'
> & {
	state: State | '00';
};
export interface IImportInput {
	address?: ImportAddress;
	name: string;
	firstName?: string;
	lastName?: string;
	instagram?: string | null;
	email?: string | null;
	isAcceptedTerms: boolean;
	phoneNumber?: string | null;
	userType: UserType;
	secretary?: string;
	registrationNumber?: string | null;
	alternateContact?: string | null;
	alternateEmail?: string | null;
	cta?: string | null;
	briefNote?: string;
	cpf?: string | null;
	cnpj?: string | null;
	rg?: string | null;
	rgType?: RGType;
	source?: string | null;
	sourceId?: string | null;
	gender?: string | null;
}
