import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as ThemeProviderMUI } from '@mui/material/styles';

import GlobalStyles from '@ui/styles/components/GlobalStyles';
import createTheme from '@ui/styles/theme';
import { FontFamily } from '@ui/styles/types/fontFamily';

import { BrandCode } from '@interfaces/index';

import AppContext from '@common-lib/AppContext';

const AppThemeProvider: React.FC<
	React.PropsWithChildren<{ fontFamily: FontFamily; brandCode?: BrandCode }>
> = ({ fontFamily, children, brandCode }) => {
	const { darkMode } = React.useContext(AppContext);
	const theme = React.useMemo(
		() => createTheme(fontFamily, darkMode, brandCode),
		[fontFamily, darkMode, brandCode],
	);
	return (
		<ThemeProviderMUI theme={theme}>
			<CssBaseline />
			<GlobalStyles fontFamily={fontFamily} />
			{children}
		</ThemeProviderMUI>
	);
};

export default AppThemeProvider;
