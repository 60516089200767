/* eslint-disable no-useless-catch */
import { useMemo } from 'react';
import {
	ApolloClient,
	ApolloLink,
	NormalizedCacheObject,
	split,
	from,
} from '@apollo/client';
// import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { createClient } from 'graphql-ws';

import { getItem } from '@common-lib/store';
import { MUTATION_SET_STATUS } from '@common-lib/modules/status/graphql';
import { StatusType } from '@common-lib/modules/status/config';

import getWs from './websocket';
import cache from './cache';
import authLink from './authLink';
import httpLink from './httpLink';
import { wsUrl } from './config';
import { resetCache, resolvers, typeDefs } from './store';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const isServer = typeof window === 'undefined';

const nothing = (..._params: any[]): null => null;
const wsClient = (opts: Record<string, any>) => {
	if (isServer) {
		return nothing;
	}
	const ws = getWs(isServer);
	if (!ws) {
		console.log('Missing websocket. Subscriptions disabled');
		return nothing;
	}
	// return nothing;
	try {
		const client = createClient({
			url: wsUrl(),
			lazy: true,
			lazyCloseTimeout: 30000,
			connectionParams: async () => {
				if (!isServer) {
					try {
						const getItemFromStorage =
							opts.localStorage?.getItem || getItem;
						if (!getItemFromStorage) {
							return {};
						}
						const token = await getItemFromStorage('token');
						return { authToken: token };
					} catch (err) {
						throw err;
					}
				}
				return undefined;
			},
		});
		return new GraphQLWsLink(client);
	} catch (err) {
		console.log('Subscription error', err);
		return nothing;
		// Do we want to throw here?
	}
};

const retry = new RetryLink({
	attempts: {
		max: 3,
		retryIf: (error, operation) =>
			!!error && operation.operationName !== 'ping',
	},
});

const link = (opts: Record<string, any> = {}) =>
	split(
		// split based on operation type
		params => {
			const { query } = params;
			const { kind, operation } = getMainDefinition(query) as any;
			// TODO should I exclude websockets from server rendering?
			return (
				kind === 'OperationDefinition' && operation === 'subscription'
			);
		},
		wsClient(opts),
		httpLink(isServer, opts),
	);

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
type ApolloClientFN = (
	e?: NormalizedCacheObject,
	opts?: Record<string, any>,
) => ApolloClient<NormalizedCacheObject>;
const createApolloClient = (opts: Record<string, any> = {}) => {
	const errorLink = onError(
		({ graphQLErrors, networkError, response, operation, forward }) => {
			try {
				const { query } = operation;
				if (graphQLErrors) {
					let validationError: { [key: string]: any } = {};

					for (const err of graphQLErrors) {
						const { message, extensions, locations, path } = err;
						const { statusCode, validationErrors = {} } =
							extensions || {};
						if (statusCode === 422) {
							// we don't want the chain to end on validation errors
							// but don't want to loose validation errors
							// response.errors = null;
							validationError = validationErrors as any;
						} else if (statusCode === 403) {
							client
								.mutate({
									mutation: MUTATION_SET_STATUS,
									variables: {
										input: {
											type: StatusType.VALIDATIONERROR,
											message:
												'Não autorizado a fazer isso',
										},
									},
								})
								.catch(console.log);
							continue; // we skip this itration to avoid it to process other errors
						} else if (statusCode === 401) {
							// NOTE: *Only in case of unauthorized*
							// we break the loop to avoid it to process other errors
							// and then logout the user
							// if (!isServer) {
							// 	window.location.href = '/auth/signout';
							// }
							break;
						} else if (
							query.definitions.some(
								(d: any) =>
									d.kind === 'OperationDefinition' &&
									d.operation === 'mutation',
							)
						) {
							validationError = { message };
						}
						console.log(
							`[GraphQL error]: Status Code: ${statusCode} Message: ${message}, Location: ${locations}, Path: ${path}`,
						);
					}

					if (Object.keys(validationError).length) {
						// TODO we currently don't have a good way to keep processing going without losing the validation error
						// https://github.com/apollographql/apollo-link/issues/1147
						client
							.mutate({
								mutation: MUTATION_SET_STATUS,
								variables: {
									input: {
										type: StatusType.VALIDATIONERROR,
										message: Object.keys(
											validationError,
										).reduce(
											(acc, key) =>
												`${acc} ${key}: ${validationError[key]}`,
											'',
										),
									},
								},
							})
							.catch(console.log);
					}
				}
			} catch (error) {
				console.log(error);
			}
			// return forward(operation);
		},
	);
	const links = [
		!isServer && retry,
		errorLink,
		authLink(isServer, opts),
		// !isServer &&
		// 	createPersistedQueryLink({
		// 		generateHash: hashAsync,
		// 	}),
		link(opts),
	].reduce((acc, value) => {
		if (typeof value !== 'boolean') {
			acc.push(value);
		}
		return acc;
	}, [] as ApolloLink[]);
	const localCache = cache();
	const client = new ApolloClient({
		ssrMode: isServer,
		connectToDevTools: true,
		// assumeImmutableResults: true,
		link: from(links),
		cache: localCache,
		typeDefs: typeDefs(),
		resolvers: resolvers(),
		defaultOptions: {
			query: {
				notifyOnNetworkStatusChange: true,
			},
		},
	});

	resetCache(client);

	if (!isServer) {
		client.onResetStore(() => resetCache(client));
	}

	return client;
};

// this will create new client for each request on ssr but not for client side
export const initializeApollo: ApolloClientFN = (
	initialState,
	options = {},
) => {
	const _apolloClient = apolloClient ?? createApolloClient(options);
	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract();

		// Merge the existing cache into data passed from getStaticProps/getServerSideProps
		const data = merge(existingCache, initialState, {
			// combine arrays using object equality (like in sets)
			arrayMerge: (destinationArray, sourceArray) => [
				...sourceArray,
				...destinationArray.filter(d =>
					sourceArray.every(s => !isEqual(d, s)),
				),
			],
		});

		// Restore the cache with the merged data
		_apolloClient.cache.restore(data);
	}

	// For SSG and SSR always create a new Apollo Client
	if (typeof window === 'undefined') return _apolloClient;

	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient;

	return _apolloClient;
};

type ApolloStateFN = <T = Record<string, any>>(
	client: ApolloClient<NormalizedCacheObject>,
	pageProps: { props: T },
) => {
	props: T;
};

export const addApolloState: ApolloStateFN = (client, pageProps) => {
	const state = client.cache.extract();
	if (pageProps?.props) {
		(pageProps.props as Record<string, any>)[APOLLO_STATE_PROP_NAME] =
			state;
	} else {
		pageProps.props = {
			[APOLLO_STATE_PROP_NAME]: state,
		} as any;
	}
	console.log(state);
	return pageProps;
};

export const useApollo: (
	e: any,
) => ApolloClient<NormalizedCacheObject> = pageProps => {
	const state = pageProps[APOLLO_STATE_PROP_NAME];
	const store = useMemo(() => initializeApollo(state), [state]);
	return store;
};
