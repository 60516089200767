import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const CheckCircle: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || palette.success.main;
	return (
		<svg
			width="100"
			height="100"
			viewBox="0 0 100 100"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...rest}
		>
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="checkCircle">
					<circle
						id="Oval-Copy"
						fill="white"
						cx="50"
						cy="50"
						r="50"
					/>
					<path
						d="M74.8760046,27.4905598 C76.8142149,25.1517249 80.2814452,24.8269545 82.6202801,26.7651648 C84.8959032,28.650991 85.264859,31.9843044 83.4978731,34.3173847 L83.3456751,34.5094402 L49.7564111,75.0415447 C47.6641104,77.5663203 43.8589143,77.7005667 41.5916473,75.3801115 L41.4285416,75.2059403 L16.9069657,47.8863603 C14.8779621,45.6258398 15.0656437,42.1484936 17.3261642,40.1194901 C19.5238924,38.1468478 22.8717838,38.2694518 24.9206552,40.3551132 L25.0930343,40.5386886 L45.355,63.112 L74.8760046,27.4905598 Z"
						id="Line"
						fill={themeColor}
						fillRule="nonzero"
					/>
				</g>
			</g>
		</svg>
	);
};

export default CheckCircle;
