import React from 'react';
import ReactGA from 'react-ga4';
import { useRouter } from 'next/router';
// import { AppProps } from 'next/app';

import config from '@common-lib/clientConfig';
import CookieContext from '@common-lib/components/cookies/CookieContext';

// Multiple products (previously known as trackers)

// // Send pageview with a custom path
// ReactGA.send({ hitType: "pageview", page: "/my-path" });

// // Send a custom event
// ReactGA.event({
// category: "your category",
// action: "your action",
// label: "your label", // optional
// value: 99, // optional, must be a number
// nonInteraction: true, // optional, true/false
// transport: "xhr", // optional, beacon/xhr/image
// });

export const registerEvent = (name: string, value: string) => {
	ReactGA.event({
		category: name,
		action: value,
	});
};

type Props = {
	children?: React.ReactNode;
	useKYGA?: boolean;
};

let gaInit = false;

const GAListener: React.FC<Props> = ({ children, useKYGA }) => {
	const { basePath, asPath } = useRouter();
	const { allowCookies } = React.useContext(CookieContext);
	React.useEffect(() => {
		if (!allowCookies) {
			return;
		}
		if (!gaInit) {
			ReactGA.initialize(
				useKYGA ? config.google.ga.idKY : config.google.ga.id,
				{
					testMode: config.google.ga.test,
					gaOptions: {},
					gtagOptions: {
						send_page_view: false,
					}, // optional
				},
			);
			gaInit = true;
		}
		ReactGA.send({
			hitType: 'pageview',
			page: `${basePath}${asPath}`,
		});
	}, [basePath, asPath, allowCookies]);

	return <>{children}</>;
};

export default GAListener;
