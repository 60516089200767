import type { ISequelizeModel, SequelizeModelKeys } from '@interfaces/model';

import { BrandCode } from './brand';

export interface IInstagramToken extends ISequelizeModel {
	brandCode: BrandCode | 'semina';
	appId: string;
	appSecret: string;
	code?: string;
	shortLived?: string;
	longLived?: string;
	expires?: Date;
}

export type InstagramTokenCreationAttributes = Omit<
	IInstagramToken,
	SequelizeModelKeys
>;

export interface IInstagramTokenInput {
	brandCode: BrandCode;
	code: string;
}

export enum MediaType {
	IMAGE = 'IMAGE',
	CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
}
export interface InstagramItem {
	id: string;
	permalink: string;
	media_type: MediaType;
	media_url: string;
	caption?: string;
}
export interface IInstagramResponse {
	data: InstagramItem[];
	paging: {
		cursors?: {
			after?: string;
			before?: string;
		};
		next?: string;
	};
}
