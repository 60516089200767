import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';

export enum BatchType {
	CRM = 'CRM',
}

export interface IBatch extends ISequelizeModel {
	dteEnd: Date | null;
	type: BatchType;
	status?: string | null;
}

export type BatchCreationAttributes = Omit<
	IBatch,
	SequelizeModelKeys | 'dteEnd'
>;
