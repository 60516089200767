import React, { ProviderProps } from 'react';

import { IAppContext } from '@interfaces/index';

const AppContext: React.Context<IAppContext> = React.createContext<IAppContext>(
	{
		googleMapsApiKey: '',
		googleMapsId: '',
		s3Proxy: '/api/s3',
		darkMode: false,
		toggleTheme: () => undefined,
		language: 'pt-br',
	},
);

const AppContextProvider: React.FC<
	ProviderProps<Omit<IAppContext, 'toggleTheme'>>
> = ({ children, value: { darkMode: darkTheme, ...rest } }) => {
	const [darkMode, setDarkMode] = React.useState(darkTheme);
	const toggleTheme = React.useCallback(() => setDarkMode(prev => !prev), []);
	return (
		<AppContext.Provider value={{ ...rest, darkMode, toggleTheme }}>
			{children}
		</AppContext.Provider>
	);
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext as default, AppContextProvider, AppContextConsumer };
