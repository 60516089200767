import React from 'react';
import clientConfig from '@common-lib/clientConfig';
import Box from '@ui/components/Box';
import Button from '@ui/components/Button';
import Text from '@ui/components/Text';
import Link from 'next/link';

const { forceHttps } = clientConfig;

const isLocalHost = (hostname: string) =>
	!!(
		hostname === 'localhost' ||
		hostname === '[::1]' ||
		hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
	);

const shouldRedirect = (forceHttps: boolean, disabled: boolean) =>
	forceHttps &&
	!disabled &&
	typeof window !== 'undefined' &&
	window.location &&
	window.location.protocol === 'http:' &&
	!isLocalHost(window.location.hostname);

const HttpsRedirect: React.FC<
	React.PropsWithChildren<{
		disabled?: boolean;
	}>
> = ({ disabled = false, children }) => {
	React.useEffect(() => {
		if (shouldRedirect(forceHttps, disabled)) {
			window.location.href = window.location.href.replace(
				/^http(?!s)/,
				'https',
			);
		}
	}, [disabled]);

	if (shouldRedirect(forceHttps, disabled)) {
		if (typeof window === 'undefined') {
			return null;
		}
		const newRef = window.location.href.replace(/^http(?!s)/, 'https');
		return (
			<Box sx={{ mt: 3, textAlign: 'center' }}>
				<Text>redirecionando para</Text>
				<Link href={newRef} passHref>
					<Button
						component="a"
						sx={{
							textDecoration: 'underline',
						}}
						variant="text"
					>
						{newRef}
					</Button>
				</Link>
			</Box>
		);
	}
	return children;
};

export default HttpsRedirect;
