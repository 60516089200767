import React from 'react';
import { Global, css } from '@emotion/react';

const GlobalStyles: React.FC<{ fontFamily: Record<string, string> }> = ({
	fontFamily,
}) => {
	const font = `${Object.values(fontFamily).join(
		',',
	)}, 'barlow', 'Open Sans', sans-serif`.trim();
	return (
		<Global
			styles={css`
				* {
					box-sizing: border-box;
					z-index: 1;
					position: relative;
				}
				html,
				body {
					padding: 0;
					margin: 0;
					font-family: ${font};
					font-size: 16px;
					background-color: #ffffff;
				}
				body {
					overflow-x: hidden;
					height: 100%;
				}
				// ::-webkit-scrollbar {
				// 	display: none;
				// }
				/* Chrome, Safari, Edge, Opera */
				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				input[type='number'] {
					-moz-appearance: textfield;
				}
				.input-label {
					padding-top: 0.5rem !important;
					padding-bottom: 0.5rem !important;
				}
				.select-label {
					padding-top: 0.5rem !important;
					padding-bottom: 0.5rem !important;
				}
				.text-label {
					padding-top: 0rem !important;
					padding-bottom: 0rem !important;
				}
				.text-label.multi {
					padding-top: 0.5rem !important;
					padding-bottom: 0.5rem !important;
				}
				.no-margin {
					margin-left: 0rem !important;
					margin-top: 0rem !important;
					margin-right: 0rem !important;
					margin-bottom: 0rem !important;
				}
				.large-info {
					min-height: 3.125rem;
					justify-content: center;
					display: flex;
					flex-direction: column;
				}
				.grecaptcha-badge {
					display: none;
				}
				.grecaptcha-logo {
					display: none;
				}
				.ReactModal__Overlay {
					z-index: 10000 !important;
				}
				.highlighted {
					position: absolute;
					bottom: 20px;
					left: 0;
					right: 0;
					margin: auto;
					max-width: fit-content;
					/* card style */
					background-color: #000;
					padding: 10px;
					border-radius: 5px;
					/* text style */
					color: #fff;
					font-size: 1rem;
					text-align: center;
					/* animation */
					transform: scale(0);
					animation: 400ms reveal forwards;
				}
				.map-marker {
					cursor: pointer;
				}
				.highlighted button {
					font-size: 1rem;
					/* color: rgb(255, 255, 107); */
					background-color: transparent;
					border: 0;
					padding: 4px;
					cursor: pointer;
				}
				@media print {
					.no-print,
					.no-print * {
						display: none;
						visibilty: hidden;
					}
					main {
						padding-top: 40px !important;
					}
				}
				.rotate-animation {
					transform-origin: center center;
					animation: rotate-animation 10s infinite;
				}
				@keyframes rotate-animation {
					from {
						transform: rotate(0deg);
					}

					to {
						transform: rotate(360deg);
					}
				}
				.query-builder {
					margin: 0rem !important;
				}
				.query-builder > div {
					padding-right: 0px !important;
				}
				.query-builder.qb-lite
					.group--header:hover
					.group--header
					.group--drag-handler,
				.query-builder.qb-lite
					.group--header:hover
					.group--header
					.group--actions,
				.query-builder.qb-lite
					.group--header:not(:hover)
					.group--drag-handler,
				.query-builder.qb-lite
					.group--header:not(:hover)
					.group--actions,
				.query-builder.qb-lite
					.rule_group:not(:hover)
					.group--drag-handler,
				.query-builder.qb-lite .rule_group:not(:hover) .group--actions {
					opacity: 1 !important;
				}
				.query-builder.qb-lite .rule--fieldsrc,
				.query-builder.qb-lite .widget--valuesrc,
				.query-builder.qb-lite .rule--drag-handler,
				.query-builder.qb-lite .rule--header {
					transition: opacity 0.2s;
				}
				.query-builder.qb-lite .rule:hover .rule .rule--fieldsrc,
				.query-builder.qb-lite .rule:hover .rule .widget--valuesrc,
				.query-builder.qb-lite .rule:hover .rule .rule--drag-handler,
				.query-builder.qb-lite .rule:hover .rule .rule--header,
				.query-builder.qb-lite .rule:not(:hover) .rule--fieldsrc,
				.query-builder.qb-lite .rule:not(:hover) .widget--valuesrc,
				.query-builder.qb-lite .rule:not(:hover) .rule--drag-handler,
				.query-builder.qb-lite .rule:not(:hover) .rule--header {
					opacity: 1 !important;
				}
				.MuiModal-root > :focus-visible {
					outline: none;
				}
				.rule--widget .rule--widget--NUMBER,
				.rule--widget--NUMBER > .widget--widget,
				.rule--widget--NUMBER .MuiTextField-root {
					min-width: 200px;
				}
			`}
		/>
	);
};

export default GlobalStyles;
