import { Theme } from '@mui/material/styles';
import useMuiMediaQuery, {
	UseMediaQueryOptions,
} from '@mui/material/useMediaQuery';

const useMediaQuery = (
	queryInput: string | ((theme: Theme) => string),
	options?: UseMediaQueryOptions,
): boolean => useMuiMediaQuery(queryInput, options);

export default useMediaQuery;
