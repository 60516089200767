import React from 'react';
import NextLink, { LinkProps as LNProps } from 'next/link';
import MUILink, { LinkProps as MUILinkProps } from '@mui/material/Link';

import { styled } from '@ui/styles';

type LinkProps = React.PropsWithChildren<
	LNProps & {
		rel?: string;
		target?: '_blank' | '_self' | '_parent' | '_top';
	}
>;

const Link: React.FC<LinkProps & { children: React.ReactNode }> = ({
	children,
	passHref,
	...props
}) => {
	return passHref ? (
		<NextLink legacyBehavior {...props}>
			{children}
		</NextLink>
	) : (
		<NextLink {...props}>{children}</NextLink>
	);
};

const DisabledLink = styled(MUILink)(() => ({
	cursor: 'default',
	pointerEvents: 'none',
}));

export type { LinkProps, MUILinkProps };
export { Link as default, MUILink, DisabledLink };
