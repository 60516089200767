import { IUserPublic } from './user';
import { RequestType } from './request';

export enum HistoryType {
	JOINED = 'JOINED',
	REQUESTED = 'REQUESTED',
	REVIEWED = 'REVIEWED',
	EVENTOPEN = 'EVENTOPEN',
	EVENTREGISTER = 'EVENTREGISTER',
	EVENTVISIT = 'EVENTVISIT',
}

export type HistorySubType = RequestType;

export interface IUserHistory {
	userId: string;
	recordId: string;
	type: HistoryType;
	subType?: HistorySubType;
	tableRef: string;
	description?: string;
	onDate: Date;
}

export type UserHistoryPublic = Omit<IUserHistory, 'userId'> & {
	user: IUserPublic;
	record: {
		id: string;
		name: string;
	};
};
