import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';

import { IUser, IUserPublic } from './user';

export enum Country {
	BR = 'BR',
}

export enum State {
	AC = 'AC',
	AL = 'AL',
	AP = 'AP',
	AM = 'AM',
	BA = 'BA',
	CE = 'CE',
	DF = 'DF',
	ES = 'ES',
	GO = 'GO',
	MA = 'MA',
	MT = 'MT',
	MS = 'MS',
	MG = 'MG',
	PA = 'PA',
	PB = 'PB',
	PR = 'PR',
	PE = 'PE',
	PI = 'PI',
	RJ = 'RJ',
	RN = 'RN',
	RS = 'RS',
	RO = 'RO',
	RR = 'RR',
	SC = 'SC',
	SP = 'SP',
	SE = 'SE',
	TO = 'TO',
}
export enum AddressType {
	BILLING = 'BILLING',
	GENERAL = 'GENERAL',
	PHARMACY = 'PHARMACY',
	REQUEST = 'REQUEST',
	EVENT_VENUE = 'EVENT_VENUE',
}

export interface IAddress extends ISequelizeModel {
	street: string;
	streetNumber: number;
	addressExtra?: string | null;
	neighbourhood?: string | null;
	city: string;
	state: State;
	country?: Country;
	zipCode: string;
	latitude?: number | null;
	longitude?: number | null;
	googlePlaceId?: string | null;
	isDefault: boolean;
	addressType: AddressType;
	refId?: string;
	userId?: string | null;
	user?: IUser | null;
}

export type LimitedAddress = Omit<
	IAddress,
	'street' | 'streetNumber' | 'zipCode'
> & {
	nullableStreet?: string | null;
	nullableStreetNumber?: number | null;
	nullableZipcode?: string | null;
};

export type AddressCreationAttributes = Omit<
	IAddress,
	SequelizeModelKeys | 'user'
>;

export type LimitedAddressInput = Omit<
	AddressCreationAttributes,
	SequelizeModelKeys | 'isDefault' | 'addressType' | 'userId'
>;

export interface IUserAddressPublic extends Omit<IAddress, 'userId' | 'user'> {
	user: IUserPublic;
}

export interface IAddressContext {
	address?: Partial<IAddress> | null;
	changeAddress: (address: Partial<IAddress>) => void;
	clearAddress: () => void;
}

export interface AddressInput
	extends Omit<IAddress, 'id' | 'createdAt' | 'updatedAt'> {}

export type SearchableAddress = Pick<
	IAddress,
	'street' | 'streetNumber' | 'city' | 'zipCode'
>;
