import { IUser, RGType } from './user';
import { IAddress } from './address';
import { BrandCode } from './brand';
import { ISequelizeModel, SequelizeModelKeys } from './model';
import { IProduct } from './product';

export interface IRequest extends ISequelizeModel {
	id: string;
	name: string;
	email: string;
	phoneNumber: string;
	message?: string;
	type?: RequestType;
	requestedProducts?: IRequestedProduct[];
	registrationNumber?: string;
	addressId?: string;
	address?: IAddress;
	userId?: string;
	user?: IUser;
	specialty?: string;
	isRemote?: boolean;
	isProcessed?: boolean;
}

export enum RequestType {
	CONTACT = 'CONTACT',
	SAMPLE = 'SAMPLE',
	VISIT = 'VISIT',
	SUPPLY = 'SUPPLY',
}

export interface IRequestedProduct {
	requestId: string;
	request: IRequest;
	productId: string;
	product: IProduct;
	quantity: number;
}

export type RequestedProductCreationAttributes = Omit<
	IRequestedProduct,
	SequelizeModelKeys | 'product' | 'request'
>;

export type RequestCreationAttributes = Omit<IRequest, SequelizeModelKeys>;

export interface IProductOrderInput {
	quantity: number;
	productId: string;
}
export interface IRequestInput {
	name: string;
	email: string;
	brandCode?: BrandCode | 'semina';
	phoneNumber: string;
	message?: string;
	type?: RequestType;
	products?: IProductOrderInput[];
	quantity?: number;
	registrationNumber?: string;
	address?: IAddress;
	specialty?: string;
	isRemote?: boolean;
	cpf?: string;
	cnpj?: string;
	rgType?: RGType;
	rg?: string;
}
