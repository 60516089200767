/* eslint-disable no-useless-catch */
/**
 * Store is used to initialize a redux like local store in graphql
 */
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

import statusGraphql from '@common-lib/modules/status/graphql';

const QUERY_ROOT = gql`
	query {
		${statusGraphql.ROOT}
	}
`;

const store = () => ({
	...statusGraphql.defaultReducer,
});

const resolvers = () => ({
	Query: {
		...statusGraphql.resolvers.Query,
	},
	Mutation: {
		...statusGraphql.resolvers.Mutation,
	},
});

const typeDefs = () => gql`
	${statusGraphql.typeDefs}
`;

const resetCache = async (client: ApolloClient<NormalizedCacheObject>) => {
	try {
		// The cache is apparently not automatically cleaned when we reset the store
		console.log(`reset cache`);
		await client.cache.reset();
		console.log(`reset store`);
		client.writeQuery({
			query: QUERY_ROOT,
			data: store(),
		});
	} catch (err) {
		throw err;
	}
};

export { store, resolvers, typeDefs, resetCache };
