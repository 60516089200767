import React from 'react';

import { BrandCode } from '@interfaces/index';

type PageProps = {
	brandCode?: BrandCode;
};

const BrandCodeContext = React.createContext<BrandCode | undefined>(undefined);

const BrandCodeContextProvider: React.FC<
	React.PropsWithChildren<PageProps>
> = ({
	children,
	brandCode, // this becomes undifined on cleint side
}) => {
	const [brand, setBrand] = React.useState<BrandCode | undefined>(undefined);
	React.useEffect(() => {
		if (brandCode !== brand) {
			setBrand(brandCode);
		}
	}, [brandCode, brand]);
	return (
		<BrandCodeContext.Provider value={brand}>
			{children}
		</BrandCodeContext.Provider>
	);
};

export { BrandCodeContext as default, BrandCodeContextProvider };
