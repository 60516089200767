/* eslint-disable react/no-children-prop */
import React from 'react';
import Box from '@mui/material/Box';

import { BoxProps as IProps } from './box';

export type BoxProps = IProps;
const TheBox: React.ForwardRefRenderFunction<HTMLDivElement, BoxProps> = (
	props,
	ref,
) => {
	const { width, fullWidth, ...rest } = props;
	return (
		<Box
			{...{
				...rest,
				ref,
				width: fullWidth ? '100%' : width,
			}}
		/>
	);
};

export default React.forwardRef(TheBox);
