import {
	ISequelizeModel,
	IAddress,
	AddressCreationAttributes,
	LimitedAddress,
	IRequest,
	ITag,
	IEventRegistration,
} from '@interfaces/index';
import { SequelizeModelKeys } from '@interfaces/model';

export enum Role {
	USER = 'USER',
	DOCTOR = 'DOCTOR',
	ADMIN = 'ADMIN',
	EVENT_MANAGER = 'EVENT_MANAGER',
	EVENT_AGENT = 'EVENT_AGENT',
	SHOPPER = 'SHOPPER',
}

export enum RGType {
	RG = 'RG',
	RNE_RNM = 'RNE_RNM',
	PASSAPORTE = 'PASSAPORTE',
}

export enum Gender {
	M = 'M',
	F = 'F',
	U = 'U',
}

export enum ContactPreference {
	PHONE = 'PHONE',
	EMAIL = 'EMAIL',
	WHATSAPP = 'WHATSAPP',
	PRESENCIAL = 'PRESENCIAL',
}

export enum UserType {
	DOCTOR = 'DOCTOR',
	STUDENT = 'STUDENT',
	FYSIOTHERAPIST = 'FYSIOTHERAPIST',
	PHARMACIST = 'PHARMACIST',
	NURSE = 'NURSE',
	USER = 'USER',
	SHOPPER = 'SHOPPER',
}

export enum CompanyType {
	PF = 'PF',
	PJ = 'PJ',
}

export interface IUserInterestTag {
	userId: string;
	tagId: string;
}

export interface IUserIntegration {
	userId: string;
	ascId?: number | null;
	blingId?: number | null;
	ascStatus?: string | null;
	ascAccountId?: string | null;
	blingStatus?: string | null;
}

export enum UserIntegrationStatus {
	ERROR = 'ERROR', // error update try update may be user already exists
	FAILED = 'FAILED', // update also failed
	PENDING = 'PENDING',
	SUCCESS = 'SUCCESS',
}

export type UserInterestTagCreationAttributes = IUserInterestTag;

export type UserIntegrationCreationAttributes = IUserIntegration;

export interface IUser extends ISequelizeModel {
	firebaseId?: string | null;
	firstName?: string;
	lastName?: string;
	image?: string;
	name?: string;
	password?: string;
	email?: string;
	phoneNumber?: string;
	alternateContact?: string;
	alternateEmail?: string;
	gender?: Gender;
	status?: boolean;
	companyId?: string;
	identificationId?: string | null;
	rgType?: RGType | null;
	rg?: string | null;
	unsubscribeReason?: string | null;
	dteUnsubscribe?: Date | null;
	optIn?: boolean;
	blocked?: boolean;
	dteBlocked?: Date | null;
	enabled?: boolean;
	emailVerified?: Date | null;
	phoneVerified?: Date | null;
	dteEnabled?: Date | null;
	isAcceptedTerms?: boolean;
	skipPhoneVerification?: boolean;
	cpf?: string | null;
	cnpj?: string | null;
	registrationNumber?: string | null;
	registrationNumberAlt?: string | null;
	defaultRole: Role;
	instagram?: string | null;
	contactPreference?: string | null;
	registrationCheck?: string | null;
	registrationVerified?: boolean | null;
	userType?: UserType | null;
	secretary?: string;
	briefNote?: string;
	addresses?: IAddress[];
	requests?: IRequest[];
	eventRegistrations?: IEventRegistration[];

	tags?: ITag[];

	source?: UserSource;
	sourceId?: string;
}

export interface IAutofillUser {
	id: string;
	name: string;
	firstName: string;
	lastName: string;
	email: string;
	gender: Gender;
	image?: string;
	defaultRole: Role;
	cpf?: string | null;
	cnpj?: string | null;
	rgType?: RGType | null;
	rg?: string | null;
	userType: UserType;
	secretary?: string;
	briefNote?: string;
	instagram?: string;
	emailVerified?: Date;
	phoneVerified?: Date;
	contactPreference: ContactPreference[];
	isAcceptedTerms: boolean;
	phoneNumber?: string;
	registrationNumber?: string;
	addresses?: LimitedAddress[];
	alternateContact?: string | null;
	alternateEmail?: string | null;
}

export interface IUserPublic
	extends Omit<
		IUser,
		'identificationId' | 'searchText' | 'contactPreference'
	> {
	contactPreference: ContactPreference[];
}

export interface IViewer {
	expiry?: Date | null;
	// TODO should this be IUserPublic?
	user?: IUser | null;
}

export type UserCreationAttributes = Omit<
	IUser,
	| SequelizeModelKeys
	| 'firebaseId'
	| 'emailVerified'
	| 'phoneVerified'
	| 'blocked'
	| 'password'
	| 'addresses'
	| 'defaultRole'
	| 'contactPreference'
	| 'userType'
	| 'dteUnsubscribe'
	| 'optIn'
	| 'unsubscribeReason'
	| 'registrationCheck'
	| 'registrationVerified'
> & {
	blocked?: boolean;
	emailVerified?: Date | null;
	phoneVerified?: Date | null;
	firebaseId?: string | null;
	addresses?: AddressCreationAttributes[];
	defaultRole?: Role;
	contactPreference?: string | null;
	userType?: UserType | null;
	optIn?: boolean;
	briefNote?: string;
};

export interface IPhotoInput {
	label: string;
	name: string;
	folder: string;
}
export interface IUserDetailInput {
	firstName: string;
	lastName: string;
	cpf?: string;
	cnpj?: string;
	rg?: string;
	rgType?: RGType;
	alternateContact?: string | null;
	alternateEmail?: string | null;
	addressId: string;
}
export interface IUserInput {
	firstName: string;
	lastName: string;
	gender: Gender;
}

export interface IAdminUserInput {
	firstName: string;
	lastName: string;
	gender: Gender;
	cpf?: string;
	cnpj?: string;
	rg?: string;
	rgType?: RGType;
	registrationNumber?: string;
	defaultRole: Role;
	alternateContact?: string | null;
	alternateEmail?: string | null;
}

export interface ITermsContext {
	accepted?: boolean;
	acceptTerms: () => void;
	rejectTerms: () => void;
}

export type TermsType = 'user';

export enum UserSource {
	ASC = 'ASC',
	BLING = 'BLING',
	IMPORT = 'IMPORT',
	SELF_REGISTER = 'SELF_REGISTER',
	EVENT = 'EVENT',
	ADMIN = 'ADMIN',
	EVENT_AGENT = 'EVENT_AGENT',
	EVENT_MANAGER = 'EVENT_MANAGER',
	PHARMEXX = 'PHARMEXX',
}
export interface ILoginLog extends ISequelizeModel {
	ipAddress: string;
	sessionId: string;
	isLoggedIn: boolean;
	loginAt: Date;
	logoutAt?: Date;
	userId: string;
}

export type LoginLogCreationAttributes = Omit<ILoginLog, SequelizeModelKeys>;
