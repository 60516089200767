import React, { ProviderProps } from 'react';

import { ITermsContext, Optional } from '@interfaces/index';

const TermsContext = React.createContext<ITermsContext>({
	accepted: false,
	acceptTerms: () => undefined,
	rejectTerms: () => undefined,
});

const TermsContextProvider: React.FC<
	Optional<
		ProviderProps<Omit<ITermsContext, 'acceptTerms' | 'rejectTerms'>>,
		'value'
	>
> = ({ children, value }) => {
	const [accepted, setAccepted] = React.useState(value?.accepted);
	const acceptTerms = React.useCallback(() => setAccepted(accepted), []);
	const rejectTerms = React.useCallback(() => setAccepted(false), []);
	return (
		<TermsContext.Provider value={{ accepted, acceptTerms, rejectTerms }}>
			{children}
		</TermsContext.Provider>
	);
};

const TermsContextConsumer = TermsContext.Consumer;

export { TermsContext as default, TermsContextProvider, TermsContextConsumer };
