import {
	createTheme as createMUITheme,
	Palette,
	ThemeOptions,
} from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';

import { FontFamily } from '@ui/styles/types/fontFamily';

import { BrandCode } from '@interfaces/index';

import getPalette from './palette';
import shape from './shape';
import getTypography from './typography';
import breakpoints from './breakpoints';
import getComponents from './overides';

const createTheme = (
	fontFamily: FontFamily,
	dark: boolean,
	brandCode?: BrandCode,
) =>
	createMUITheme(
		{
			fontFamily,
			shape,
			palette: getPalette(dark, brandCode),
			typography: (palette: Palette) =>
				getTypography(fontFamily, palette),
			breakpoints,
			spacing: 8,
			components: getComponents(fontFamily, brandCode),
			// direction?: Direction,
			// transitions?: TransitionsOptions,
			// shadows?: Shadows,
			// mixins?: MixinsOptions,
			// zIndex?: ZIndexOptions,
			// unstable_strictMode?: boolean,
			// unstable_sxConfig?: SxConfig,
		} as ThemeOptions,
		ptBR,
	);

export default createTheme;
