import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as ThemeProviderMUI } from '@mui/material/styles';

import GlobalStyles from '@ui/styles/components/GlobalStyles';
import createTheme from '@ui/styles/theme';
import { FontFamily } from '@ui/styles/types/fontFamily';

import AppContext from '@common-lib/AppContext';

const ThemeProviderStyled: React.FC<
	React.PropsWithChildren<{ fontFamily: FontFamily }>
> = ({ children, fontFamily }) => {
	const { darkMode } = React.useContext(AppContext);
	const theme = React.useMemo(
		() => createTheme(fontFamily, darkMode),
		[fontFamily, darkMode],
	);
	return (
		<ThemeProviderMUI theme={theme}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles fontFamily={fontFamily} />
				{children}
			</ThemeProvider>
		</ThemeProviderMUI>
	);
};

export default ThemeProviderStyled;
