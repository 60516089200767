export const getItem: (key: string) => string = key =>
	localStorage.getItem(key) || '';

export const setItem: (key: string, value: string) => void = (key, value) =>
	localStorage.setItem(key, value);

export const removeItem: (key: string) => void = key =>
	localStorage.removeItem(key);

export const clear: (key: string) => void = () => localStorage.clear();
