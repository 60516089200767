import Typography from '@mui/material/Typography';

import { styled } from '@ui/styles';

import TextProps from './Text';

const Text = styled(Typography, {
	shouldForwardProp: prop => prop !== 'numberOfLines',
})<TextProps>(({ numberOfLines }) => ({
	...(numberOfLines
		? {
				lineClamp: `${numberOfLines}`,
				display: '-webkit-box',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: `${numberOfLines}`,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			}
		: {}),
}));
export type { TextProps };
export default Text;
