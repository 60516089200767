import React from 'react';

import Box, { BoxProps } from '@ui/components/Box';
import Text from '@ui/components/Text';
import Button from '@ui/components/Button';
import Portal from '@ui/components/Portal';
import Smiley from '@ui/icons/Smiley';

type Props = {
	hash: string;
	overlay?: boolean;
	iconWidth?: string | number;
	iconHeight?: string | number;
	onReset: () => void;
} & Omit<BoxProps, 'ref'>;

const Error400: React.FC<Props> = ({
	overlay,
	iconWidth,
	iconHeight,
	hash,
	children,
	onReset,
	...props
}) => {
	return overlay ? (
		<Portal>
			<Box
				{...props}
				zIndex={10}
				position="absolute"
				left={0}
				top={80}
				height="100vh"
				width="100vw"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				<Box display="flex" justifyContent="center">
					<Smiley height={iconHeight} width={iconWidth} />
				</Box>
				{children || (
					<Box mt={2} textAlign="center">
						<Text
							color="warning.main"
							variant="body1"
							fontWeight="bold"
						>
							Alguma coisa deu errado.
						</Text>
						<Text variant="body1" fontWeight="medium" gutterBottom>
							Identificador do erro: {hash}
						</Text>
						<Button
							variant="contained"
							color="primary"
							onClick={onReset}
						>
							Tentar recarregar
						</Button>
					</Box>
				)}
			</Box>
		</Portal>
	) : (
		<Box
			mt={10}
			{...props}
			display="flex"
			flexDirection="column"
			alignItems="center"
		>
			<Smiley height={iconHeight} width={iconWidth} />
			{children || (
				<Box mt={2} textAlign="center">
					<Text
						color="warning.main"
						variant="body1"
						fontWeight="bold"
					>
						Alguma coisa deu errado.
					</Text>
					<Text variant="body1" fontWeight="medium" gutterBottom>
						Identificador do erro: {hash}
					</Text>
					<Button
						variant="contained"
						color="primary"
						onClick={onReset}
					>
						Tentar recarregar
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default Error400;
