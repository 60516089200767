// import '@fontsource/libre-franklin';
// import '@fontsource/libre-franklin/300.css';
// import '@fontsource/libre-franklin/400.css';
// import '@fontsource/libre-franklin/500.css';
// import '@fontsource/libre-franklin/700.css';
import '@ui/components/LightBox/style.css';

import mobile from 'is-mobile';
import { ApolloProvider } from '@apollo/client';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { Mulish } from 'next/font/google';
import localFont from 'next/font/local';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// import { SpeedInsights } from '@vercel/speed-insights/next';
// import { Analytics } from '@vercel/analytics/react';
import SnackbarProvider from '@ui/components/SnackbarProvider';
import AppThemeProvider from '@ui/styles/components/AppThemeProvider';
import { FontFamily } from '@ui/styles/types/fontFamily';

import { AppContextProvider } from '@common-lib/AppContext';
import clientConfig from '@common-lib/clientConfig';
import ErrorBoundary from '@common-lib/components/ErrorBoundary';
import GAListener from '@common-lib/components/ga/GAListener';
import { useApollo } from '@common-lib/graphql';
import { TermsContextProvider } from '@common-lib/modules/account/components/TermsContext';
import GeneralSnackbars from '@common-lib/modules/status/components/GeneralSnackbars';
import { AddressContextProvider } from '@common-lib/modules/address/components/AddressContext';
import PageCommon from '@common-lib/PageCommon';
import EmailGuard from '@common-lib/components/auth/EmailGuard';

import createEmotionCache from '@web/utils/createEmotionCache';
import { Page } from '@web/types';
import { MobileContextProvider } from '@web/components/MobileContext';
import { BrandCodeContextProvider } from '@web/components/BrandCodeContext';
import HttpsRedirect from '@web/components/HttpsRedirect';
import { useRouter } from 'next/router';
// import Container from '@web/components/layout/Container';
// import FacebookPixel from '@web/components/FacebookPixel';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// TODO getServerSideProps is not supported here yet.
// We would want to use that to pass the mobile status to the PageContextProvider
interface MyAppProps extends Omit<AppProps, 'Component'> {
	emotionCache?: EmotionCache;
	session?: Session;
	isMobile: boolean;
	Component: Page;
}

const mulish = Mulish({
	weight: ['200', '500', '600', '800', '900'],
	display: 'swap',
	subsets: ['latin'],
});

const chalet = localFont({
	src: '../fonts/Chalet-NewYorkNineteenEighty.woff',
});

const fontFamily: FontFamily = {
	mulish: mulish.style.fontFamily,
	chalet: chalet.style.fontFamily,
};

// export function reportWebVitals(metric: NextWebVitalsMetric) {
// 	console.log(metric);
// }

const MyApp: React.FC<MyAppProps> = ({
	Component,
	emotionCache = clientSideEmotionCache,
	isMobile: propsIsMobile,
	pageProps: { session, brandCode, useKYGA, ...pageProps },
}) => {
	const isMobile = mobile();
	// React.useEffect(() => {
	// 	if (
	// 		typeof window !== 'undefined' &&
	// 		process.env.NODE_ENV === 'development'
	// 	) {
	// 		window.scroll(0, document.body.offsetHeight);
	// 	}
	// });
	const client = useApollo({
		session,
		brandCode,
		...pageProps,
	});
	const { pathname } = useRouter();

	return (
		<>
			<MobileContextProvider isMobile={propsIsMobile || isMobile}>
				<CacheProvider value={emotionCache}>
					<Head>
						<meta charSet="utf-8" />
						<meta
							name="viewport"
							content="minimal-ui, width=device-width, initial-scale=1, viewport-fit=cover"
						/>
						<meta
							name="apple-mobile-web-app-capable"
							content="yes"
						/>
						<meta name="mobile-web-app-capable" content="yes" />
						<meta
							name="apple-mobile-web-app-title"
							content="Vrumona"
						/>
						<meta
							name="apple-mobile-web-app-capable"
							content="yes"
						/>
						<meta
							name="apple-mobile-web-app-status-bar-style"
							content="black-translucent"
						/>
					</Head>
					{/* <FacebookPixel /> */}
					<SessionProvider session={session}>
						<EmailGuard />
						<AppContextProvider value={clientConfig}>
							<ApolloProvider client={client}>
								<AppThemeProvider
									fontFamily={fontFamily}
									brandCode={brandCode}
								>
									<HttpsRedirect>
										{/* <Container maxWidth="xl"> */}
										<ErrorBoundary>
											<SnackbarProvider>
												<LocalizationProvider
													dateAdapter={AdapterLuxon}
													adapterLocale="pt-Br"
												>
													<PageCommon
														pathname={pathname}
													>
														<>
															<GAListener
																useKYGA={
																	useKYGA
																}
															/>

															<AddressContextProvider>
																<TermsContextProvider>
																	<BrandCodeContextProvider
																		brandCode={
																			brandCode
																		}
																	>
																		<Component
																			brandCode={
																				brandCode
																			}
																			{...pageProps}
																		/>
																	</BrandCodeContextProvider>
																</TermsContextProvider>
															</AddressContextProvider>
														</>
													</PageCommon>
												</LocalizationProvider>

												<GeneralSnackbars />
											</SnackbarProvider>
										</ErrorBoundary>
										{/* </Container> */}
									</HttpsRedirect>
								</AppThemeProvider>
							</ApolloProvider>
						</AppContextProvider>
					</SessionProvider>
				</CacheProvider>
			</MobileContextProvider>
			{/* <SpeedInsights />
			<Analytics /> */}
		</>
	);
};

export default MyApp;
