import { Operation } from '@apollo/client';

export const optOutBatchPrefix: string[] = [];

// This opts you out of http batching
const optout = (operation: Operation) =>
	!operation.operationName ||
	optOutBatchPrefix.findIndex(prefix =>
		operation.operationName.startsWith(prefix),
	) > -1;

export default optout;
