export const graphqlUrl: () => string = () => {
	if (typeof window !== 'undefined') {
		return (
			(window as any).__APOLLO_URL__ ||
			`${process.env.NEXT_PUBLIC_GRAPHQL_API_URL}`
		);
	}
	return `${process.env.NEXT_PUBLIC_GRAPHQL_API_URL}`;
};

export const wsUrl: () => string = () => {
	if (typeof window !== 'undefined') {
		return (
			(window as any).__APOLLO_WS_URL__ ||
			`${process.env.NEXT_PUBLIC_GRAPHQL_WS_URL}`
		);
	}
	return `${process.env.NEXT_PUBLIC_GRAPHQL_WS_URL}`;
};
