import { SequelizeModelKeys } from '@interfaces/model';

import { IUser } from './user';

export enum PhotoVerificationStatus {
	APPROVED = 'APPROVED',
	DECLINED = 'DECLINED',
	BLURRY = 'BLURRY',
	NOFACE = 'NOFACE',
	NOCARD = 'NOCARD',
	NOPHOTOCARD = 'NOPHOTOCARD',
	DARK = 'DARK',
	BRIGHT = 'BRIGHT',
	OTHER = 'OTHER',
}

export interface IPhotoVerification {
	id: string;
	userId: string;
	reviewedBy?: string | null;
	label: string;
	name: string;
	folder: string;
	status: PhotoVerificationStatus;
	reason?: string;
	createdAt?: Date;
	updatedAt?: Date;
	user?: IUser;
	reviewer?: IUser;
}

export type PhotoVerificationCreationAttributes = Omit<
	IPhotoVerification,
	SequelizeModelKeys | 'user'
>;

export interface IPhotoVerificationInput {
	label: string;
	name: string;
	folder: string;
}

export interface IPhotoVerificationApprovalInput {
	status: PhotoVerificationStatus;
	reason?: string;
}

export type IPhotoVerificationPublic = Omit<IPhotoVerification, 'userId'>;
