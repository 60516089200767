import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';

import { IUser } from './user';

export interface IExport extends ISequelizeModel {
	filename: string;
	type: string;
	status: ExportStatus;
	userId: string;
	user: IUser;
}

export enum ExportStatus {
	INPROGRESS = 'INPROGRESS',
	COMPLETED = 'COMPLETED',
	ERROR = 'ERROR',
}

export type ExportCreationAttributes = Omit<
	IExport,
	SequelizeModelKeys | 'user'
>;
