import React, { ProviderProps } from 'react';

import { IAddress, IAddressContext, Optional } from '@interfaces/index';

const AddressContext = React.createContext<IAddressContext>({
	address: null,
	changeAddress: () => undefined,
	clearAddress: () => undefined,
});

const AddressContextProvider: React.FC<
	Optional<
		ProviderProps<Omit<IAddressContext, 'changeAddress' | 'clearAddress'>>,
		'value'
	>
> = ({ children, value }) => {
	const [address, setAddress] = React.useState(value?.address);
	const changeAddress = React.useCallback(
		(address: Partial<IAddress>) => setAddress(address),
		[],
	);
	const clearAddress = React.useCallback(() => setAddress(null), []);
	return (
		<AddressContext.Provider
			value={{ address, changeAddress, clearAddress }}
		>
			{children}
		</AddressContext.Provider>
	);
};

const AddressContextConsumer = AddressContext.Consumer;

export {
	AddressContext as default,
	AddressContextProvider,
	AddressContextConsumer,
};
