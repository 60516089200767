import memoize from 'lodash/memoize';

import introspectionQueryResultData from '@common-lib/resources/fragmentTypes.json';

export const getPossibleTypes = memoize(() => {
	const possibleTypes: Record<string, any> = {};
	// we import this inline bc the fragmentTypes can possibly not be there on first load and this file is imported server side.
	introspectionQueryResultData.__schema.types.forEach(supertype => {
		if (supertype.possibleTypes) {
			possibleTypes[supertype.name] = supertype.possibleTypes.map(
				subtype => subtype.name,
			);
		}
	});
	return possibleTypes;
});
