import React from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

const EmailGuard: React.FC = () => {
	const { data } = useSession();
	const { push, asPath } = useRouter();
	const noEmail = data?.user && !data?.user.email;
	React.useEffect(() => {
		if (noEmail) {
			push({
				pathname: '/account/email/[[...redirectUrl]]',
				query: {
					redirectUrl: asPath
						.replace('/account/email/', '')
						.replace('/account/email', '')
						.replace('%2F?required=1', '')
						.replace('?required=1', ''),
					required: 1,
				},
			});
		}
	}, [noEmail]);
	return null;
};

export default EmailGuard;
