import { UrlObject } from 'url';

export * from '@interfaces/graphql-common';
export * from '@interfaces/address';
export * from '@interfaces/batch';
export * from '@interfaces/brand';
export * from '@interfaces/blog';
export * from '@interfaces/chat';
export * from '@interfaces/cookie';
export * from '@interfaces/doctor';
export * from '@interfaces/event';
export * from '@interfaces/eventRegistration';
export * from '@interfaces/mailCampaign';
export * from '@interfaces/nextPage';
export * from '@interfaces/pharmacy';
export * from '@interfaces/postmark';
export * from '@interfaces/tag';
export * from '@interfaces/user';
export * from '@interfaces/session';
export * from '@interfaces/account';
export * from '@interfaces/verificationToken';
export * from '@interfaces/filter';
export * from '@interfaces/graphql-common';
export * from '@interfaces/invites';
export * from '@interfaces/faq';
export * from '@interfaces/model';
export * from '@interfaces/photoVerification';
export * from '@interfaces/upload';
export * from '@interfaces/search';
export * from '@interfaces/news';
export * from '@interfaces/product';
export * from '@interfaces/instagram';
export * from '@interfaces/request';
export * from '@interfaces/integration';
export * from '@interfaces/utm';
export * from '@interfaces/userSelection';
export * from '@interfaces/mail';
export * from '@interfaces/export';
export * from '@interfaces/userHistory';
export * from '@interfaces/import';

export type DropDownOption = {
	label: string;
	value: string | number;
};
export interface MergeOption {
	reverse?: boolean;
	concat?: boolean;
	preventInfiniteKeys: string[];
}

export interface IAppContext {
	host?: string;
	s3Proxy: string;
	graphqlUrl?: string;
	graphqlWSUrl?: string;
	appName?: string;
	googleMapsApiKey: string;
	googleMapsId: string;
	darkMode: boolean;
	toggleTheme: () => void;
	language: string | 'pt-br' | 'en-us';

	// [key: string]: any;
}

export type WithLocation<T = any> = {
	location: { lat: number; lng: number };
} & T;

export type Url = UrlObject | string;
export interface TransitionOptions {
	shallow?: boolean;
	locale?: string | false;
	scroll?: boolean;
}

export type RouterPush = (
	url: Url,
	as?: Url,
	options?: TransitionOptions,
) => Promise<boolean> | void;

export type RouterReplace = (
	url: Url,
	as?: Url,
	options?: TransitionOptions,
) => Promise<boolean> | void;

export type ModuleConfig = {
	frontEndUrl: string;
	[key: string]: any;
};

export type AsyncValueType<T> = T extends Promise<infer U> ? U : T;

export type AsyncReturnType<T extends (...args: any) => Promise<any>> =
	AsyncValueType<ReturnType<T>>;
