import { gql } from '@apollo/client';

import { IResolvers } from '@interfaces/index';

import { StatusType } from './config';

const ROOT = `
	local__status
	local__connectionStatus
`;

const typeDefs = `
	enum Local__StatusType {
		CONNECTION
		SERVERDOWN
		OK
		INFO
		VALIDATIONERROR
		ERROR
	}
	type Local__Status {
		type: StatusType!
		message: String
		# autoHideDuration: Int!
	}
	type Local__ConnectionStatus {
		connected: Boolean!
	}
	input Local__StatusInput {
		type: Local__StatusType!
		message: String
	}
	input Local__StatusResetInput {
		types: [Local__StatusType]!
	}
	extend type Query {
		local__status: Local__Status!
		local__connectionStatus: Local__ConnectionStatus!
	}
	extend type Mutation {
		local__setStatus(input: Local__StatusInput!): Local__Status!
		local__setConnectionStatus(connected: Boolean!): Local__ConnectionStatus!
		local__resetStatus(input: Local__StatusResetInput): Local__Status
	}
`;

const defaultReducer = {
	local__status: null,
	local__connectionStatus: {
		__typename: 'ConnectionStatus',
		connected: true,
	},
};

const STATUS_FRAGMENT = gql`
	fragment statusFragment on Local__Status {
		type
		message
		# action
		# autoHideDuration
	}
`;

export const QUERY_STATUS = gql`
	query local__Status {
		local__status @client {
			...statusFragment
		}
	}
	${STATUS_FRAGMENT}
`;

export const QUERY_CONNECTION_STATUS = gql`
	query Local__ConnectionStatus {
		local__connectionStatus @client {
			connected
		}
	}
`;

export const MUTATION_SET_CONNECTION_STATUS = gql`
	mutation Local__SetConnectionStatus($connected: Boolean!) {
		local__setConnectionStatus(connected: $connected) @client {
			connected
		}
	}
`;

export const MUTATION_SET_STATUS = gql`
	mutation Local__SetStatus($input: Local__StatusInput!) {
		local__setStatus(input: $input) @client {
			...statusFragment
		}
	}
	${STATUS_FRAGMENT}
`;

export const MUTATION_RESET_STATUS = gql`
	mutation Local__ResetStatus($input: Local__StatusResetInput) {
		local__resetStatus(input: $input) @client {
			...statusFragment
		}
	}
	${STATUS_FRAGMENT}
`;

const resolvers: IResolvers = {
	Query: {
		local__status: (_root, _args, { client }) => {
			const res = client.readQuery({ query: QUERY_STATUS });
			return res.local__status;
		},
		local__connectionStatus: (_root, _args, { client }) => {
			const res = client.readQuery({ query: QUERY_CONNECTION_STATUS });
			return res.local__connectionStatus;
		},
	},
	Mutation: {
		local__setStatus: (_root, { input }, { client }) => {
			if (!input) {
				client.writeQuery({
					query: QUERY_STATUS,
					data: {
						local__status: null,
					},
				});
				return null;
			}
			const { type, message } = input;
			if (type === StatusType.VALIDATIONERROR && !message) {
				// We don't want to overwrite existing specific validation errors with general validation errors
				const existing = client.readQuery({ query: QUERY_STATUS });
				if (
					existing.local__status?.type === StatusType.VALIDATIONERROR
				) {
					return existing.local__status;
				}
			}
			client.writeQuery({
				query: QUERY_STATUS,
				data: {
					local__status: {
						type,
						message,
						__typename: 'Local__Status',
					},
				},
			});
			return { type, message, __typename: 'Local__Status' };
		},
		local__setConnectionStatus: (_root, { connected }, { client }) => {
			client.writeQuery({
				query: QUERY_CONNECTION_STATUS,
				data: {
					local__connectionStatus: {
						connected,
						__typename: 'Local__ConnectionStatus',
					},
				},
			});
			return { connected, __typename: 'Local__ConnectionStatus' };
		},
		local__resetStatus: (_root, { input }, { client }) => {
			const { types } = input || {};
			const existing = client.readQuery({ query: QUERY_STATUS });
			if (!types || types.includes(existing?.local__status?.type)) {
				client.writeQuery({
					query: QUERY_STATUS,
					data: {
						local__status: null,
					},
				});
				return null;
			} else {
				return existing?.local__status || null;
			}
		},
	},
};

const g = {
	typeDefs,
	resolvers,
	defaultReducer,
	ROOT,
};

export default g;
