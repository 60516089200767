import React from 'react';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';

const TheSnackbarProvider: React.FC<SnackbarProviderProps> = ({
	maxSnack,
	...props
}) => {
	return <SnackbarProvider maxSnack={maxSnack || 3} {...props} />;
};

export type { SnackbarProviderProps };
export default TheSnackbarProvider;
