import { InMemoryCache } from '@apollo/client';
// import { NextSSRInMemoryCache } from '@apollo/experimental-nextjs-app-support/ssr';

import typePolicies from './typePolicies';
import { getPossibleTypes } from './helpers';

const possibleTypes = getPossibleTypes();

const cache: () => InMemoryCache = () => {
	if (!Object.keys(possibleTypes)) {
		console.log('WARNING. Possible fragment types is empty');
	}
	const theWindow =
		typeof window !== 'undefined' ? (window as any) : undefined;
	if (!theWindow?.__APOLLO_STATE__) {
		return new InMemoryCache({
			possibleTypes,
			typePolicies,
		});
	}
	return new InMemoryCache({
		possibleTypes,
		typePolicies,
	}).restore(
		theWindow.__APOLLO_STATE__, // eslint-disable-line
	);
};

// const ssrcache: () => NextSSRInMemoryCache = () => {
// 	const possibleTypes = getPossibleTypes();
// 	if (!Object.keys(possibleTypes)) {
// 		console.log('WARNING. Possible fragment types is empty');
// 	}
// 	const theWindow =
// 		typeof window !== 'undefined' ? (window as any) : undefined;
// 	if (!theWindow?.__APOLLO_STATE__) {
// 		return new NextSSRInMemoryCache({
// 			possibleTypes,
// 			typePolicies: typePolicies as any,
// 		});
// 	}
// 	return new NextSSRInMemoryCache({
// 		possibleTypes,
// 		typePolicies: typePolicies as any,
// 	}).restore(
// 		theWindow.__APOLLO_STATE__, // eslint-disable-line
// 	);
// };

// export { cache as default, ssrcache };
export default cache;
