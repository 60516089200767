import { BrandCode } from './brand';

export interface ISearchResult {
	id: string;
	title: string;
	category: SearchCategory;
	brandCode?: BrandCode;
	photo?: string;
	videoLink?: string;
	slug: string;
	venue?: string;
}

export enum SearchCategory {
	BLOGPOST = 'blogPost',
	EVENT = 'event',
	NEWS = 'news',
	PRODUCT = 'product',
}
export enum SearchType {
	ALL = 'ALL',
	EVENT = 'EVENT',
	NEWS = 'NEWS',
	POST = 'POST',
	VIDEOS = 'VIDEOS',
}
