import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const Img: React.FC<IconProps> = ({
	color,
	htmlColor,
	width = 201,
	height = 201,
}) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#634B99';
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 201 201"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="100.372"
				cy="100.259"
				r="95"
				stroke={themeColor}
				strokeWidth="10"
			/>
			<circle cx="64.2747" cy="75.1182" r="16" fill={themeColor} />
			<circle cx="139.134" cy="75.1182" r="16" fill={themeColor} />
			<path
				d="M64.2747 154.23C85.069 115.302 120.281 115.901 139.134 156.326"
				stroke={themeColor}
				strokeWidth="10"
			/>
		</svg>
	);
};

Img.displayName = 'Smiley';

export default Img;
