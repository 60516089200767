'use client';

import React from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

import Link from '@ui/components/Link';
import Box from '@ui/components/Box';
import Button from '@ui/components/Button';
import { useTheme } from '@ui/styles/hooks';
import { styled } from '@ui/styles';

import {
	CookieContextProvider,
	CookieContextConsumer,
} from '@common-lib/components/cookies/CookieContext';

const ContainerBox = styled(Box)(({ theme }) => ({
	position: 'initial',
	'& .cookie-container': {
		alignItems: 'center!important',
		paddingRight: 16,
	},
	'& .cookie-container *': {
		position: 'initial',
	},
	'& .cookie-button-wrapper': {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			paddingBottom: 16,
			textAlign: 'center',
		},
	},
}));

const PageCommon: React.FC<React.PropsWithChildren<{ pathname: string }>> = ({
	children,
	pathname,
}) => {
	const consent = getCookieConsentValue('trackingAccept');
	const { palette } = useTheme();

	return (
		<CookieContextProvider cookieName="trackingAccept">
			{pathname.includes('auth') ||
			consent !== undefined ||
			typeof window === 'undefined' ? null : (
				<CookieContextConsumer>
					{({ acceptCookies, declineCookies }) => (
						<ContainerBox>
							<CookieConsent
								location="bottom"
								buttonText="Aceitar"
								enableDeclineButton
								declineButtonText="Declinar"
								cookieName="trackingAccept"
								containerClasses="cookie-container"
								buttonWrapperClasses="cookie-button-wrapper"
								ButtonComponent={(props: {
									id: string;
									className: string;
									style: any;
									children: string;
									onClick: () => void;
									'aria-label': string;
								}) => {
									const { style, children, ...rest } = props;
									return (
										<Button
											variant={
												children === 'Aceitar'
													? 'contained'
													: 'text'
											}
											// @ts-ignore it exists
											color="primary"
											size="medium"
											sx={{
												m: 1,
												color:
													children === 'Aceitar'
														? undefined
														: palette.text.primary,
											}}
											{...rest}
										>
											{children}
										</Button>
									);
								}}
								overlay={!pathname.startsWith('/privac')}
								style={{
									background: palette.primary.light,
									color: palette.text.primary,
								}}
								expires={999}
								onAccept={acceptCookies}
								onDecline={declineCookies}
							>
								<Box
									p={1}
									sx={{
										lineHeight: '1.5rem',
										textAlign: {
											xs: 'center',
											sm: 'left',
										},
									}}
								>
									Este site usa cookies para melhorar a
									experiência do usuário. Ao utilizar o nosso
									website, estará concordando com todos os
									cookies de acordo com nossa Política de
									Privacidade.{' '}
									<Link href="/privacidade" legacyBehavior>
										<a>Ler mais</a>
									</Link>
								</Box>
							</CookieConsent>
						</ContainerBox>
					)}
				</CookieContextConsumer>
			)}
			{children}
		</CookieContextProvider>
	);
};

export default PageCommon;
