import type { ISequelizeModel, SequelizeModelKeys } from '@interfaces/model';

import { BrandCode } from '../brand';

export enum IntegrationPlatform {
	ASC = 'ASC',
	BLING = 'BLING',
	RDSTATION = 'RDSTATION',
}

export interface IIntegrationDBToken extends ISequelizeModel {
	code?: string;
	access_token?: string;
	refresh_token?: string;
	client_secret?: string;
	client_id?: string;
	host?: string;
	expires?: Date;
	platform: IntegrationPlatform;
	brandCode: BrandCode | 'semina';
}

export interface IIntegrationToken {
	code?: string;
	access_token?: string;
	refresh_token?: string;
	aboutToExpire?: boolean;
	expires_in?: number;
	expiresAtDate?: string;
	client_secret?: string;
	client_id?: string;
	host?: string;
}

export interface IIntegrationTokenDBAdapter {
	getToken: () => PromiseLike<IIntegrationToken | null>;
	setToken: (
		token: IIntegrationToken,
	) => PromiseLike<IIntegrationToken | null>;
}

export type IntegrationTokenCreationAttributes = Omit<
	IIntegrationDBToken,
	SequelizeModelKeys
>;

export interface IIntegrationTokenInput {
	code: string;
}

export * from './rdstation/contacts';
export * from './rdstation/event';
export * from './rdstation/common';
export * from './bling';
export * from './asc';
