import { State } from '@interfaces/index';

export interface BlingContactListParams {
	/** page number */
	pagina?: number;
	/** limit */
	limite?: number;
	/** query */
	pesquisa?: string;
	/** search criteria include exclude etc */
	criterio?: BlingListingCriteria;
	/** inclusion date in format yyyy-mm-dd */
	dataInclusaoInicial?: string; //
	/** initial change date in format yyyy-mm-dd */
	dataInclusaoFinal?: string;
	/** final change date in format yyyy-mm-dd */
	dataAlteracaoFinal?: string;
	/** contact type id */
	idTipoContato?: number;
	/** vendor */
	idVendedor?: number;
	uf?: State;
	telefone?: string;
	/** bling ids */
	idsContatos?: number[];
	/** cpf cnpj */
	numeroDocumento?: string;
}
export interface BlingContactItem {
	id: number;
	nome: string;
	codigo: string;
	situacao: string;
	numeroDocumento: string;
	telefone: string;
	celular: string;
}

export interface BlingAddress {
	endereco: string;
	cep: string;
	bairro: string;
	municipio: string;
	uf: string;
	numero: string;
	complemento: string;
}

export interface BlingContact {
	id: number;
	nome: string;
	codigo: string;
	situacao: string;
	numeroDocumento: string;
	telefone: string;
	celular: string;
	fantasia: string;
	tipo: string;
	indicadorIe: number;
	ie: string;
	rg: string;
	/** RG TYPE */
	orgaoEmissor: string;
	email: string;
	endereco: {
		geral: BlingAddress;
		cobranca: BlingAddress;
	};
	vendedor?: {
		id: number;
	};
	dadosAdicionais?: {
		dataNascimento?: string; // Consider using Date type if parsed
		sexo: string;
		naturalidade: string;
	};
	financeiro?: {
		limiteCredito: number;
		condicaoPagamento: string;
		categoria: {
			id: number;
		};
	};
	pais?: {
		nome: string;
	};
	tiposContato?: {
		id: number;
		descricao: string;
	}[];
	pessoasContato?: {
		id: number;
		descricao: string;
	}[];
}

export type BlingContactInput = Omit<BlingContact, 'id'>;

export enum BlingContactStatus {
	A = 'A', // Ativo
	E = 'E', // Excluído
	I = 'I', // Inativo
	S = 'S', // Sem movimentação
}

export enum BlingContactType {
	J = 'J', // Jurídica
	F = 'F', // Física
	E = 'E', // Estrangeira
}

export enum BlingContactTaxStatus {
	ONE = 1, // Contribuinte ICMS
	TWO = 2, // Contribuinte isento de Inscrição no cadastro de Contribuintes
	NINE = 9, // Não Contribuinte
}

export enum BlingListingCriteria {
	ONE = 1, // Todos
	TWO = 2, // Excluídos
	THERE = 3, // Últimos incluídos
	FOUR = 4, // Sem movimento
}
