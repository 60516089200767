import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const InfoCircle: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || '#E19017';
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M10 0C4.47552 0 0 4.47552 0 10C0 15.5245 4.47552 20 10 20C15.5245 20 20 15.5245 20 10C20 4.47552 15.5245 0 10 0ZM11.3753 15.9207C11.3753 16.2238 11.1189 16.4802 10.8159 16.4802H9.20746C8.90443 16.4802 8.64802 16.2238 8.64802 15.9207V14.2424C8.64802 13.9394 8.90443 13.683 9.20746 13.683H10.8159C11.1189 13.683 11.3753 13.9394 11.3753 14.2424V15.9207ZM11.3753 11.7949C11.3753 12.0979 11.1189 12.3543 10.8159 12.3543H9.20746C8.90443 12.3543 8.64802 12.0979 8.64802 11.7949V4.80186C8.64802 4.49883 8.90443 4.24242 9.20746 4.24242H10.8159C11.1189 4.24242 11.3753 4.49883 11.3753 4.80186V11.7949Z"
				fill={themeColor}
			/>
		</svg>
	);
};

export default InfoCircle;
