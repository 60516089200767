import React from 'react';

import { useTheme } from '@ui/styles/hooks';

import { IconProps } from './props';

const ErrorCircle: React.FC<IconProps> = ({ color, htmlColor, ...rest }) => {
	const palette: any = useTheme().palette;
	const themeColor =
		(color && palette[color]?.main) || htmlColor || palette.error.main;
	return (
		<svg
			version="1.2"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 52 52"
			width="52"
			height="52"
			{...rest}
		>
			<path
				fill={themeColor}
				d="m26 1.6c13.4 0 24.3 10.8 24.3 24.2 0 13.4-10.9 24.3-24.3 24.3-13.4 0-24.3-10.9-24.3-24.3 0-13.4 10.9-24.2 24.3-24.2z"
			/>
			<path
				fill="white"
				d="m38.6 34.7c1.2 1.2 1.2 3.2 0 4.4-0.6 0.6-1.4 0.9-2.2 0.9-0.8 0-1.6-0.3-2.2-0.9l-7.8-7.8-7.8 7.8c-0.6 0.6-1.4 0.9-2.2 0.9-0.8 0-1.6-0.3-2.2-0.9-1.2-1.2-1.2-3.2 0-4.4l7.8-7.7-7.8-7.8c-1.2-1.2-1.2-3.1 0-4.3 1.2-1.2 3.2-1.2 4.4 0l7.8 7.7 7.8-7.7c1.2-1.2 3.2-1.2 4.4 0 1.2 1.2 1.2 3.1 0 4.3l-7.8 7.8z"
			/>
		</svg>
	);
};

export default ErrorCircle;
