import React from 'react';
import Button from '@mui/material/Button';

import { ButtonProps as IProps } from './button';

export type ButtonProps = IProps;

const TheButton: React.ForwardRefRenderFunction<
	HTMLButtonElement,
	ButtonProps
> = ({ variant, size, ...props }, ref) => {
	return (
		<Button
			{...{
				...props,
				variant: variant || 'contained',
				size: size || 'large',
				ref,
			}}
		/>
	);
};
// TODO fix text variant color
export default React.forwardRef(TheButton);
