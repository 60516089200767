import { Components } from '@mui/material/styles';
import deepmerge from 'deepmerge';

import { FontFamily } from '@ui/styles/types/fontFamily';

import { BrandCode } from '@interfaces/brand';

export const getCustom = (brandCode?: BrandCode): Components => {
	switch (brandCode) {
		case BrandCode.AMAMENTE:
			return {};
		case BrandCode.DELLA:
			return {};
		case BrandCode.KY:
			return {};
		case BrandCode.KYCLINICAL:
			return {};
		case BrandCode.NUAA:
			return {
				MuiButton: {
					defaultProps: {
						disableElevation: true,
					},
					styleOverrides: {
						root: {
							// borderRadius: 0,
						},
					},
				},
				MuiOutlinedInput: {
					styleOverrides: {
						root: {
							// borderRadius: 0,
						},
					},
				},
			};

		default:
			return {};
	}
};

const components = (
	fontFamily: FontFamily,
	brandCode?: BrandCode,
): Components => {
	const family = `${Object.values(fontFamily).join(',')}`.trim();
	return deepmerge(
		{
			MuiCssBaseline: {
				styleOverrides: {
					fontFamily: family,
				},
			},
			MuiButton: {
				defaultProps: {
					disableElevation: true,
				},
				styleOverrides: {
					root: {
						lineHeight: '1rem',
						borderRadius: 30,
						fontSize: '1rem',
						fontFamily: fontFamily.chalet,
						fontWeight: '700',
					},
					text: {
						':hover': {
							backgroundColor: 'transparent',
							opacity: 0.9,
						},
					},
					sizeSmall: {
						paddingTop: 7,
						'& svg': {
							marginTop: -4,
						},
					},
					sizeMedium: {
						minHeight: 40,
						paddingLeft: 25,
						paddingRight: 25,
					},
					sizeLarge: {
						'@media (max-width: 600px)': {
							minHeight: 40,
							fontSize: '1rem',
							paddingLeft: 22,
							paddingRight: 22,
						},
						minHeight: 60,
						paddingLeft: 26,
						paddingRight: 26,
					},
					containedSecondary: {
						':hover': {
							backgroundColor: '#634B99D9',
						},
						'&:active': {
							backgroundColor: '#634B99',
						},
						'&& .MuiTouchRipple-child': {
							backgroundColor: '#634B99',
						},
					},
				},
			},
			MuiFormLabel: {
				styleOverrides: {
					root: {
						marginLeft: 24,
						minHeight: 10,
						fontSize: '0.6875rem',
						letterSpacing: '0.0625rem',
						lineHeight: '0.9375rem',
						fontWeight: 'bold',
						fontFamily: fontFamily.mulish,
					},
				},
			},
			MuiFormControlLabel: {
				styleOverrides: {
					root: {
						'&.Mui-error': {
							color: '#BF291C',
						},
						'&.Mui-error > .MuiFormControlLabel-label': {
							color: '#BF291C',
						},
						'&.Mui-error > .MuiCheckbox-root > svg': {
							border: '1px solid #BF291C',
						},
					},
					label: {
						fontSize: '0.6875rem',
						letterSpacing: '0.0625rem',
						lineHeight: '0.9375rem',
						fontWeight: 'bold',
						fontFamily: fontFamily.mulish,
						textAlign: 'left',
					},
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						paddingTop: 4.5,
						paddingBottom: 4.5,
					},
				},
			},
			MuiFormHelperText: {
				styleOverrides: {
					root: {
						marginLeft: 24,
						marginRight: 0,
						fontFamily: fontFamily.mulish,
						fontSize: '0.6275rem',
						letterSpacing: '0.0625rem',
						lineHeight: '0.75rem',
						fontWeight: 'bold',
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					paddingCheckbox: {
						paddingLeft: 12,
					},
				},
			},
			MuiOutlinedInput: {
				defaultProps: {
					notched: false,
				},
				styleOverrides: {
					root: {
						borderRadius: 30,
						backgroundColor: '#FFFFFF',
						minHeight: 60,
						'@media (max-width: 600px)': {
							minHeight: 48,
							// paddingRight: 8,
						},
						// paddingRight: 12,
						'&.Mui-error': {
							borderColor: '#BF291C',
						},
						'&.Mui-error > input': {
							'::placeholder': {
								color: '#BF291C',
							},
							'::-webkit-input-placeholder': {
								color: '#BF291C',
							},
						},
						':hover > fieldset': {
							borderColor: '#00000047 !important',
						},
					},
					input: {
						paddingLeft: 25,
						paddingRight: 25,
						minHeight: 25,
						fontFamily: fontFamily.mulish,
						'::placeholder': {
							fontFamily: fontFamily.mulish,
						},
						'::-webkit-input-placeholder': {
							fontFamily: fontFamily.mulish,
						},
					},
					multiline: {
						paddingLeft: 0,
						paddingRight: 0,
					},
					notchedOutline: {
						borderColor: '#d9d9d9',
						':hover': {
							borderColor: '#00000047 !important',
						},
					},
				},
			},
			MuiBreadcrumbs: {
				styleOverrides: {
					root: {
						marginBottom: 40,
						marginTop: -40,
					},
					ol: {
						'li:nth-last-of-type(2) > svg > path': {
							opacity: 1,
						},
					},
					separator: {
						'& > svg > path': {
							opacity: 0.3,
						},
					},
					li: {
						fontFamily: fontFamily.chalet,
						fontWeight: '700',
						fontSize: '0.75rem',
						lineHeight: '1rem',
					},
				},
			},
			MuiLink: {
				styleOverrides: {
					root: {
						cursor: 'pointer',
					},
				},
			},
			MuiTimelineOppositeContent: {
				styleOverrides: {
					root: {
						maxWidth: 140,
						paddingLeft: 0,
					},
				},
			},
			MuiTimelineSeparator: {
				styleOverrides: {
					root: {
						position: 'relative',
					},
				},
			},
			MuiTimelineConnector: {
				styleOverrides: {
					root: {
						'&.top': {
							height: 32,
							position: 'absolute',
							top: -19,
						},
					},
				},
			},
			MuiTimelineItem: {
				styleOverrides: {
					root: {
						'&:before': {
							maxWidth: 200,
						},
					},
				},
			},
		},
		getCustom(brandCode),
	);
};

export default components;
