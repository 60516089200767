import { Palette } from '@mui/material/styles';

import { BrandCode } from '@interfaces/index';

export const darkCommonPalette: Omit<
	Palette,
	'getContrastText' | 'augmentColor' | 'primary' | 'secondary'
> = {
	mode: 'dark',
	common: {
		black: '#000000',
		white: '#FFFFFF',
	},
	error: {
		lighter: '#BF291C4c',
		light: '#E57373',
		main: '#BF291C',
		dark: '#9B3229',
		contrastText: '#ffffff',
	},
	warning: {
		lighter: '#C32A854c',
		light: '#FFF2FA',
		main: '#C32A85',
		dark: '#EC008C',
		contrastText: '#FFFFFF',
	},
	info: {
		lighter: '#A3A3A34c',
		light: '#EEEEEE',
		main: '#AAAAAA',
		dark: '#757575',
		contrastText: '#634B99',
	},
	white: {
		light: '#FFFFFF4d',
		main: '#FFFFFF',
		dark: '#FFFFFF',
		contrastText: '#000000',
	},
	success: {
		lighter: '#99E2994c',
		light: '#99E299',
		main: '#4dd74d',
		dark: '#2BBA8F',
		contrastText: '#FFFFFF',
	},
	text: {
		primary: '#000000',
		secondary: '#0E3E67',
		disabled: '#AAAAAA',
	},
	// NOTE: Please, keep it to material-ui approach 50 - 900 and A100 - A700
	grey: {
		50: '#fafafa',
		100: '#f5f5f5',
		200: '#eeeeee',
		300: '#EFF1F1',
		400: '#DBDBDB',
		500: '#9e9e9e',
		600: '#757575',
		700: '#616161',
		800: '#1D293F',
		900: '#212121',
		A100: '#d5d5d5',
		A200: '#aaaaaa',
		A400: '#303030',
		A700: '#616161',
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	divider: 'rgba(0, 0, 0, 0.12)',
	background: {
		paper: '#FFFFFF',
		default: '#F5D28C',
	},
	action: {
		active: 'rgba(0, 0, 0, 0.54)',
		hover: 'rgba(0, 0, 0, 0.04)',
		hoverOpacity: 0.04,
		selected: 'rgba(0, 0, 0, 0.08)',
		selectedOpacity: 0.08,
		disabled: 'rgba(0, 0, 0, 0.26)',
		disabledBackground: 'rgba(0, 0, 0, 0.12)',
		disabledOpacity: 0.38,
		focus: 'rgba(0, 0, 0, 0.12)',
		focusOpacity: 0.12,
		activatedOpacity: 0.12,
	},

	// getContrastText: function (background: string): string {
	// 	throw new Error('Function not implemented.');
	// },
	// augmentColor: function (options: PaletteAugmentColorOptions): PaletteColor {
	// 	throw new Error('Function not implemented.');
	// },
};
export const getDarkPalette = (
	brandCode?: BrandCode,
): Omit<Palette, 'getContrastText' | 'augmentColor'> => {
	switch (brandCode) {
		case BrandCode.AMAMENTE:
			return {
				primary: {
					lighter: '#F8F8F8',
					light: '#E3E7F3',
					dark: '#7389C8',
					main: '#7389C8',
					contrastText: '#ffffff',
				},
				secondary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#c4878f4d',
					light: '#C4878F',
					dark: '#9A4856',
					main: '#9A4856',
					contrastText: '#ffffff',
				},
				...darkCommonPalette,
			};
		case BrandCode.DELLA:
			return {
				primary: {
					lighter: '#F8F8F8',
					light: '#88338EB3',
					dark: '#88338E',
					main: '#88338E',
					contrastText: '#ffffff',
				},
				secondary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#c4878f4d',
					light: '#C4878F',
					dark: '#9A4856',
					main: '#9A4856',
					contrastText: '#ffffff',
				},
				...darkCommonPalette,
			};
		case BrandCode.KY:
			return {
				primary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#DD073433',
					light: '#DD07344d',
					dark: '#DD0734',
					main: '#DD0734',
					contrastText: '#ffffff',
				},
				secondary: {
					lighter: '#00A8C533',
					light: '#00A8C54d',
					dark: '#00A8C5',
					main: '#00A8C5',
					contrastText: '#ffffff',
				},
				...darkCommonPalette,
			};
		case BrandCode.KYCLINICAL:
			return {
				primary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#c4878f4d',
					light: '#C4878F',
					dark: '#9A4856',
					main: '#9A4856',
					contrastText: '#ffffff',
				},
				secondary: {
					lighter: '#F8F8F8',
					light: '#F8F4FF',
					dark: '#634B99',
					main: '#634B99',
					contrastText: '#ffffff',
				},
				...darkCommonPalette,
			};
		case BrandCode.NUAA:
			return {
				primary: {
					lighter: '#D7D7D74d',
					light: '#0000004d',
					dark: '#000000',
					main: '#000000',
					contrastText: '#ffffff',
				},
				secondary: {
					lighter: '#83AA5E33',
					light: '#83AA5E80',
					dark: '#83AA5E',
					main: '#83AA5E',
					contrastText: '#000000',
					// contrastText: '#ffffff',
				},
				...darkCommonPalette,
			};

		default:
			return {
				primary: {
					lighter: '#F8F8F8',
					light: '#F8F4FF',
					dark: '#634B99',
					main: '#634B99',
					contrastText: '#ffffff',
				},
				secondary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#c4878f4d',
					light: '#C4878F',
					dark: '#9A4856',
					main: '#9A4856',
					contrastText: '#ffffff',
				},
				...darkCommonPalette,
			};
	}
};

export const lightCommonPalette: Omit<
	Palette,
	'getContrastText' | 'augmentColor' | 'primary' | 'secondary'
> = {
	mode: 'light',
	common: {
		black: '#000000',
		white: '#FFFFFF',
	},
	error: {
		light: '#E57373',
		main: '#BF291C',
		dark: '#9B3229',
		contrastText: '#ffffff',
	},
	warning: {
		light: '#FFF2FA',
		main: '#C32A85',
		dark: '#EC008C',
		contrastText: '#FFFFFF',
	},
	info: {
		light: '#EEEEEE',
		main: '#AAAAAA',
		dark: '#757575',
		contrastText: '#634B99',
	},
	white: {
		light: '#FFFFFF4d',
		main: '#FFFFFF',
		dark: '#FFFFFF',
		contrastText: '#000000',
	},
	success: {
		light: '#99E299',
		main: '#4dd74d',
		dark: '#2BBA8F',
		contrastText: '#FFFFFF',
	},
	text: {
		primary: '#000000',
		secondary: '#0E3E67',
		disabled: '#AAAAAA',
	},
	// NOTE: Please, keep it to material-ui approach 50 - 900 and A100 - A700
	grey: {
		50: '#fafafa',
		100: '#f5f5f5',
		200: '#eeeeee',
		300: '#EFF1F1',
		400: '#DBDBDB',
		500: '#9e9e9e',
		600: '#757575',
		700: '#616161',
		800: '#1D293F',
		900: '#212121',
		A100: '#d5d5d5',
		A200: '#aaaaaa',
		A400: '#303030',
		A700: '#616161',
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	divider: 'rgba(0, 0, 0, 0.12)',
	background: {
		paper: '#FFFFFF',
		default: '#F8F4FF',
	},
	action: {
		active: 'rgba(0, 0, 0, 0.54)',
		hover: 'rgba(0, 0, 0, 0.04)',
		hoverOpacity: 0.04,
		selected: 'rgba(0, 0, 0, 0.08)',
		selectedOpacity: 0.08,
		disabled: 'rgba(0, 0, 0, 0.26)',
		disabledBackground: 'rgba(0, 0, 0, 0.12)',
		disabledOpacity: 0.38,
		focus: 'rgba(0, 0, 0, 0.12)',
		focusOpacity: 0.12,
		activatedOpacity: 0.12,
	},
	// getContrastText: function (background: string): string {
	// 	throw new Error('Function not implemented.');
	// },
	// augmentColor: function (options: PaletteAugmentColorOptions): PaletteColor {
	// 	throw new Error('Function not implemented.');
	// },
};
export const getLightPalette = (
	brandCode?: BrandCode,
): Omit<Palette, 'getContrastText' | 'augmentColor'> => {
	switch (brandCode) {
		case BrandCode.AMAMENTE:
			return {
				primary: {
					lighter: '#F8F8F8',
					light: '#E3E7F3',
					dark: '#7389C8',
					main: '#7389C8',
					contrastText: '#ffffff',
				},
				secondary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#c4878f4d',
					light: '#C4878F',
					dark: '#9A4856',
					main: '#9A4856',
					contrastText: '#ffffff',
				},
				...lightCommonPalette,
			};
		case BrandCode.DELLA:
			return {
				primary: {
					lighter: '#F8F8F8',
					light: '#88338EB3',
					dark: '#88338E',
					main: '#88338E',
					contrastText: '#ffffff',
				},
				secondary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#c4878f4d',
					light: '#C4878F',
					dark: '#9A4856',
					main: '#9A4856',
					contrastText: '#ffffff',
				},
				...lightCommonPalette,
			};
		case BrandCode.KY:
			return {
				primary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#DD073433',
					light: '#DD07344d',
					dark: '#DD0734',
					main: '#DD0734',
					contrastText: '#ffffff',
				},
				secondary: {
					lighter: '#0E3E6733',
					light: '#0E3E674d',
					dark: '#0E3E67',
					main: '#0E3E67',
					contrastText: '#ffffff',
				},
				...lightCommonPalette,
			};
		case BrandCode.KYCLINICAL:
			return {
				primary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#c4878f4d',
					light: '#C4878F',
					dark: '#9A4856',
					main: '#9A4856',
					contrastText: '#ffffff',
				},
				secondary: {
					lighter: '#F8F8F8',
					light: '#F8F4FF',
					dark: '#634B99',
					main: '#634B99',
					contrastText: '#ffffff',
				},
				...lightCommonPalette,
			};
		case BrandCode.NUAA:
			return {
				primary: {
					lighter: '#D7D7D74d',
					light: '#0000004d',
					dark: '#000000',
					main: '#000000',
					contrastText: '#ffffff',
				},
				secondary: {
					lighter: '#83AA5E33',
					light: '#83AA5E80',
					dark: '#83AA5E',
					main: '#83AA5E',
					contrastText: '#000000',
					// contrastText: '#ffffff',
				},
				...lightCommonPalette,
			};

		default:
			return {
				primary: {
					lighter: '#F8F8F8',
					light: '#F8F4FF',
					dark: '#634B99',
					main: '#634B99',
					contrastText: '#ffffff',
				},
				secondary: {
					// lighter: 'rgba(196, 135, 143, 0.3)',
					lighter: '#c4878f4d',
					light: '#C4878F',
					dark: '#9A4856',
					main: '#9A4856',
					contrastText: '#ffffff',
				},
				...lightCommonPalette,
			};
	}
};

const getPalette = (dark: boolean, brandCode?: BrandCode) =>
	dark ? getDarkPalette(brandCode) : getLightPalette(brandCode);

export default getPalette;
