import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';
import {
	AddressCreationAttributes,
	LimitedAddress,
	LimitedAddressInput,
	IUser,
	IUserSelection,
} from '@interfaces/index';

import { BrandCode } from './brand';

export enum EventType {
	EVENT = 'EVENT',
	LANDING_PAGE = 'LANDING_PAGE',
}
export interface IEvent extends ISequelizeModel {
	label: string;
	desc: string;
	// main brand
	brandCode: BrandCode | 'semina';
	// other brands
	associatedBrands: string;
	emailDesc: string;
	dteStart: Date;
	dteEnd: Date | null;
	enabled: boolean;
	phoneVerify: boolean;
	addressId?: string | null;
	venue?: string | null;
	hideHomepage?: boolean;
	askForSecretary?: boolean;
	requireCPFCNPJ?: boolean;
	requireAddress?: boolean;
	dontSendMails?: boolean;
	alwaysRedirectToHome?: boolean;
	address?: AddressCreationAttributes | null;
	coverImage?: string | null;
	coverImageMobile?: string | null;
	thankyouMessage?: string | null;
	type: EventType;
	slug: string;
	doctorsOnly?: boolean;
	userSelectionId?: string | null;
	userSelection?: IUserSelection | null;
}

export type AdminEvent = Omit<IEvent, 'address' | 'associatedBrands'> & {
	address: LimitedAddress;
	viewCount?: number;
	submitCount?: number;
	associatedBrands: BrandCode[];
};

export type EventPublic = Omit<
	IEvent,
	'address' | 'userSelection' | 'associatedBrands'
> & {
	address: LimitedAddress;
	viewCount?: number;
	submitCount?: number;
	associatedBrands: BrandCode[];
};

export type EventCreationAttributes = Omit<
	IEvent,
	SequelizeModelKeys | 'userSelection'
>;

export interface IEventInput {
	label: string;
	desc: string;
	brandCode: BrandCode | 'semina';
	emailDesc: string;
	venue?: string | null;
	dteStart: Date;
	dteEnd?: Date | null;
	enabled: boolean;
	phoneVerify: boolean;
	hideHomepage?: boolean;
	address: LimitedAddressInput | null;
	askForSecretary?: boolean;
	requireCPFCNPJ?: boolean;
	requireAddress?: boolean;
	dontSendMails?: boolean;
	alwaysRedirectToHome?: boolean;
	coverImage?: string | null;
	coverImageMobile?: string | null;
	thankyouMessage?: string | null;
	type: EventType;
	slug: string;
	doctorsOnly?: boolean;
	userSelectionId?: string | null;
	associatedBrands: BrandCode[];
}

export interface IEventView extends ISequelizeModel {
	ipAddress: string;
	userId: string | null;
	user: IUser | null;
	eventId: string;
}

export interface EventViewCreationAttributes {
	ipAddress: string | null;
	userId: string | null;
	eventId: string;
}
