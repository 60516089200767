export enum CRMStatus {
	A = 'Regular',
	B = 'Suspensão parcial permanente',
	C = 'Cassado',
	E = 'Inoperante',
	F = 'Falecido',
	G = 'Sem o exercício da profissão na UF',
	I = 'Interdição cautelar - total',
	J = 'Suspenso por ordem judicial - parcial',
	L = 'Cancelado',
	M = 'Suspensão total temporária',
	N = 'Interdição cautelar - parcial',
	O = 'Suspenso por ordem judicial - total',
	P = 'Aposentado',
	R = 'Suspensão temporária',
	S = 'Suspenso - total',
	T = 'Transferido',
	X = 'Suspenso - parcial',
}

export type CRM = {
	crm: string;
	dataAutalizacao: string | null;
	dataNascimento: string | null;
	especialidade: string | null;
	imagem: string | null;
	nome: string;
	numeroCPF: string | null;
	situacao: string;
	tipoInscricao: string;
	uf: string;
	verificarAutoriazacao: string | null;
};
export type CRMError = {
	errorCode: string;
};

export type CRMResult = CRM | CRMError;
