import {
	State,
	Gender,
	IUser,
	UserType,
	IUserSelection,
} from '@interfaces/index';
import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';
import { IEvent } from '@interfaces/event';

export enum ErrorCode {}

export interface ISentMail extends ISequelizeModel {
	campaignId: string;
	campaign: IMailCampaign;
	userId: string;
	user: IUser;
	email: string;
	status?: string;
	success: boolean;
}

export type SentMailCreationAttributes = Omit<
	ISentMail,
	SequelizeModelKeys | 'user' | 'campaign'
>;

export interface IMailCampaign extends ISequelizeModel {
	id: string;
	label: string;
	desc?: string;
	// subject: string;
	link: string;
	templateId: string;
	mailsSent?: number;
	percentageSent?: number;
	amountFails?: number;
	amountPending?: number;
	sentMails: ISentMail[];
	userCount?: number;
	eventId?: string | null;
	event: IEvent | null;
	userType?: string | null;
}

export interface XMailCampaign extends Omit<IMailCampaign, 'userType'> {
	userType: UserType[] | null;
}

export type MailCampaignCreationAttributes = Omit<
	IMailCampaign,
	SequelizeModelKeys | 'percentageSent' | 'mailsSent' | 'event' | 'sentMails'
>;

export interface IMailCampaignInput {
	id?: string;
	label: string;
	desc?: string;
	templateId: string;
}

export interface MailUser {
	id: string;
	email: string;
	name: string;
	cta?: string;
}

export interface MailModel {
	host: string;
	gender?: Gender;
	name: string;
	link?: string;
	state?: State;
}

export interface MailResponse {
	/** postmark message id if send with postmark*/
	postmarkMsgId?: string;
	email: string;
	success: boolean;
	status?: string;
}
