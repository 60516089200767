'use client';
import React from 'react';
import createCache, { Options } from '@emotion/cache';
import { useServerInsertedHTML } from 'next/navigation';
import { CacheProvider } from '@emotion/react';
// import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import GlobalStyles from '@ui/styles/components/GlobalStyles';
import { FontFamily } from '@ui/styles/types/fontFamily';
import createTheme from '@ui/styles/theme';

import AppContext from '@common-lib/AppContext';

// This implementation is from emotion-js
// https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
const ThemeRegistry: React.FC<
	React.PropsWithChildren<{ options: Options; fontFamily: FontFamily }>
> = ({ options, children, fontFamily }) => {
	const { darkMode } = React.useContext(AppContext);
	const theme = React.useMemo(
		() => createTheme(fontFamily, darkMode),
		[fontFamily, darkMode],
	);
	const [{ cache, flush }] = React.useState(() => {
		const cache = createCache(options);
		cache.compat = true;
		const prevInsert = cache.insert;
		let inserted: string[] = [];
		cache.insert = (...args) => {
			const serialized = args[1];
			if (cache.inserted[serialized.name] === undefined) {
				inserted.push(serialized.name);
			}
			return prevInsert(...args);
		};
		const flush = () => {
			const prevInserted = inserted;
			inserted = [];
			return prevInserted;
		};
		return { cache, flush };
	});

	useServerInsertedHTML(() => {
		const names = flush();
		if (names.length === 0) {
			return null;
		}
		let styles = '';
		for (const name of names) {
			styles += cache.inserted[name];
		}
		return (
			<style
				key={cache.key}
				data-emotion={`${cache.key} ${names.join(' ')}`}
				dangerouslySetInnerHTML={{
					__html: styles,
				}}
			/>
		);
	});

	return (
		<CacheProvider value={cache}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles fontFamily={fontFamily} />
				{children}
			</ThemeProvider>
		</CacheProvider>
	);
};

export default ThemeRegistry;
