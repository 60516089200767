import doctorTypePolicy from '@common-lib/modules/doctor/graphql/typePolicy';

const typePolicies = {
	Query: {
		fields: {},
	},
	...doctorTypePolicy,
};

export default typePolicies;
