export enum RDStationIdentifier {
	UUID = 'UUID',
	EMAIL = 'email',
}

export enum RDStationCommunicationType {
	EMAIL = 'EMAIL',
	SMS = 'SMS',
	PUSH = 'PUSH',
}

export enum RDStationEventType {
	CONVERSION = 'CONVERSION',
	OPPORTUNITY = 'OPPORTUNITY',
}

export enum RDStationEventFamily {
	CDP = 'CDP',
}

export interface RDStationLegalBases {
	category: 'communications' | 'data_processing';
	status: 'declined' | 'granted';
	type:
		| 'consent'
		| 'judicial_process'
		| 'legitimate_interest'
		| 'pre_existent_contract'
		| 'pre_existent_contract'
		| 'vital_interest';
}

export interface RDStationLink {
	rel: string;
	href: string;
	media: string;
	type: string;
}
