export enum BrandCode {
	KY = 'ky',
	KYCLINICAL = 'ky-clinical',
	NUAA = 'nuaa',
	DELLA = 'della',
	AMAMENTE = 'amamente',
}
export interface IBrand {
	brandCode: BrandCode;
	title: string;
	desc: string;
}
