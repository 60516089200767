import React from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';

import { Optional, ICookieContext } from '@interfaces/index';

const CookieContext: React.Context<ICookieContext> =
	React.createContext<ICookieContext>({
		allowCookies: false,
		acceptCookies: () => undefined,
		declineCookies: () => undefined,
	});

const CookieContextProvider: React.FC<
	Optional<
		React.ProviderProps<
			Omit<ICookieContext, 'acceptCookies' | 'declineCookies'>
		>,
		'value'
	> & { cookieName: string }
> = ({ cookieName, children, value }) => {
	const [isAllowCookies, setIsAllowCookies] = React.useState<boolean>(
		!!value?.allowCookies,
	);
	React.useMemo(() => {
		const consent = getCookieConsentValue(cookieName);
		setIsAllowCookies(consent === 'true');
	}, []);
	const acceptCookies = React.useCallback(() => {
		setIsAllowCookies(true);
	}, []);
	const declineCookies = React.useCallback(() => {
		setIsAllowCookies(false);
	}, []);
	return (
		<CookieContext.Provider
			value={{
				allowCookies: isAllowCookies,
				acceptCookies,
				declineCookies,
			}}
		>
			{children}
		</CookieContext.Provider>
	);
};

const CookieContextConsumer = CookieContext.Consumer;

export {
	CookieContext as default,
	CookieContextProvider,
	CookieContextConsumer,
};
