/* eslint-disable no-restricted-imports */
import { Palette } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { FontFamily } from '@ui/styles/types/fontFamily';

export const font = {
	weight: {
		black: 900,
		extraBold: 900,
		bold: 700,
		semiBold: 600,
		medium: 500,
		regular: 400,
		light: 300,
	},
	size: {
		extraSmall: '0.68rem',
		smaller: '0.75rem',
		small: '0.875rem',
		regular: '1rem',
		medium: '2.25rem',
		large: '2.5rem',
		extraLarge: '3rem',
	},
};

/**
 * Default typography
 * https://material-ui.com/customization/default-theme/
 */
const color = '#000';

const typography = (
	fontFamily: FontFamily,
	palette: Palette,
): TypographyOptions => {
	const family = `${Object.values(fontFamily).join(',')}`.trim();
	return {
		htmlFontSize: 16,
		fontFamily: family,
		fontSize: 16,
		fontWeightLight: font.weight.light,
		fontWeightRegular: font.weight.regular,
		fontWeightMedium: font.weight.medium,
		fontWeightBold: font.weight.bold,
		h1: {
			fontFamily: fontFamily.chalet,
			fontWeight: font.weight.bold,
			fontSize: font.size.extraLarge,
			// lineHeight: font.size.extraLarge,
			lineHeight: '3.5rem',
			color,
			'@media (max-width: 600px)': {
				fontSize: '1.75rem',
				lineHeight: '2rem',
			},
		},
		h2: {
			fontFamily: fontFamily.chalet,
			fontWeight: font.weight.bold,
			fontSize: font.size.large,
			lineHeight: font.size.large,
			color,
			// '@media (max-width: 600px)': {
			// 	color: 'red',
			// },
		},
		h3: {
			fontWeight: font.weight.bold,
			fontFamily: fontFamily.chalet,
			fontSize: font.size.medium,
			lineHeight: font.size.medium,
			color,
		},
		h4: {
			fontWeight: font.weight.bold,
			fontSize: font.size.regular,
			lineHeight: font.size.regular,
			color,
			// '@media (max-width: 600px)': {
			// 	color: 'red',
			// },
		},
		h5: {
			fontWeight: font.weight.medium,
			fontSize: font.size.small,
			lineHeight: font.size.small,
			color,
			// '@media (max-width: 600px)': {
			// 	color: 'red',
			// },
		},
		h6: {
			fontWeight: font.weight.medium,
			fontSize: font.size.smaller,
			lineHeight: font.size.smaller,
			color,
			// '@media (max-width: 600px)': {
			// 	color: 'red',
			// },
		},
		subtitle1: {
			fontWeight: font.weight.regular,
			fontSize: font.size.regular,
			lineHeight: font.size.regular,
		},
		subtitle2: {
			fontWeight: font.weight.medium,
			fontSize: font.size.small,
			lineHeight: font.size.regular,
		},
		body1: {
			fontSize: '1.125rem',
			lineHeight: '1.125rem',
			fontWeight: font.weight.medium,
			color,
		},
		body2: {
			fontWeight: font.weight.regular,
			fontSize: font.size.smaller,
			lineHeight: font.size.smaller,
			color,
		},
		button: {
			fontWeight: font.weight.bold,
			fontSize: font.size.small,
			textTransform: 'inherit',
		},
		caption: {
			fontWeight: font.weight.regular,
			fontSize: font.size.smaller,
			lineHeight: font.size.regular,
		},
		overline: {
			fontWeight: font.weight.regular,
			fontSize: font.size.smaller,
			lineHeight: '2.66rem',
			textTransform: 'uppercase',
		},
	};
};

export default typography;
