const config = {
	host: `${process.env.NEXT_PUBLIC_HOST || 'http://localhost:3000'}`,
	forceHttps: process.env.NEXT_PUBLIC_FORCE_HTTPS === 'true' || false,
	s3Proxy: `${
		process.env.NEXT_PUBLIC_S3_PROXY || 'http://localhost:3000/api/s3'
	}`,
	graphqlUrl: `${
		process.env.NEXT_PUBLIC_GRAPHQL_API_URL ||
		'http://localhost:3000/api/graphql'
	}`,
	graphqlWSUrl: `${
		process.env.NEXT_PUBLIC_GRAPHQL_WS_URL ||
		'http://localhost:3000/api/graphql-subscription'
	}`,
	appName: `${process.env.NEXT_PUBLIC_APP_NAME || 'semina'}`,
	darkMode: process.env.NEXT_PUBLIC_DARK_MODE === 'true' || false,
	googleMapsApiKey: process.env.NEXT_PUBLIC_GMAP_KEY || '',
	googleMapsId: process.env.NEXT_PUBLIC_GMAP_ID || '',
	ongoingEvent: {
		id: process.env.NEXT_PUBLIC_ONGOING_EVENT_ID || '',
		tag: process.env.NEXT_PUBLIC_ONGOING_EVENT_TAG || '',
	},
	language: 'pt-br',
	cookieName: `${process.env.JWT_COOKIE_NAME || 'next-auth.session-token'}`,
	facebook: {
		pixelId: `${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || '1234'}`,
	},
	instagram: {
		redirectUri: `${process.env.NEXT_PUBLIC_HOST}/admin/instagram`,
	},
	integration: {
		redirectUri: `${process.env.NEXT_PUBLIC_HOST}/admin/integration`,
	},
	google: {
		ga: {
			id: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`,
			idKY: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KYCLINICAL_ID}`,
			test: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TEST === 'true',
		},
	},
	firebase: {
		appName: `${process.env.NEXT_PUBLIC_FIREBASE_APP_NAME || 'semina-web'}`,
		apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || '',
		authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || '',
		databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL || '',
		projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || '',
		storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || '',
		messagingSenderId:
			process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || '',
		appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || '',
		measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || '',
	},
	// mercadoPago: {
	// 	clientToken: `${
	// 		process.env.NEXT_PUBLIC_MERCADOPAGO_CLIENT_TOKEN || ''
	// 	}`,
	// },
};

export default config;
