import React from 'react';
import { useQuery } from '@apollo/client';

import Snackbar from '@ui/components/Snackbar';
import AlertIcon from '@ui/icons/InfoCircle';

import { QUERY_CONNECTION_STATUS } from '@common-lib/modules/status/graphql';

const ConnectionSnackbar = () => {
	const connectionData = useQuery(QUERY_CONNECTION_STATUS);
	const isConnected =
		connectionData.data === undefined
			? true
			: connectionData.data.local__connectionStatus?.connected;
	return (
		<Snackbar
			open={!isConnected}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			autoHideDuration={6000}
			alertProps={{
				severity: 'error',
				icon: <AlertIcon htmlColor="white" />,
			}}
			message="Sem Internet."
		/>
	);
};

export default React.memo(ConnectionSnackbar);
